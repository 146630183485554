import axios from 'axios';
import { updateDeliveryWhenFetched } from '../../database/set-delivery-data';
import { fetchAllDeliveriesData } from '../../database/fetch-deliveries-data';

const CARRIER_CODES = {
  'U.S. Postal Service': 'usps',
  'Stamps.com': 'stamps_com',
  'FedEx': 'fedex',
  'UPS': 'ups',
  'DHL Express': 'dhl_express',
  'DHL ECommerce': 'dhl_global_mail',
  'DHL eCommerce Australia': 'dhl_ecommerce_au',
  'Access Worldwide': 'access_worldwide',
  'APC': 'apc',
  'Aramex AU / Fastway': 'fastway_au',
  'Aramex NZ / Fastway': 'fastway_nz',
  'Asendia': 'asendia',
  'Australia Post': 'australia_post',
  'Australia Post eParcel': 'australia_post',
  'Australia Post MyPost Business': 'australia_post_mypost_business',
  'Canada Post': 'canada_post',
  'Canpar Express': 'canpar',
  'CouriersPlease': 'couriers_please',
  'DAI': 'dai',
  'Direct Freight': 'direct_freight',
  'Evri International': 'evri_international',
  'First Mile': 'firstmile',
  'Freightways': 'freightways',
  'Freightways - Castle Parcels': 'castle_parcels',
  'Freightways - New Zealand Couriers': 'new_zealand_couriers',
  'Freightways - Now Couriers': 'now_couriers',
  'Freightways - Post Haste': 'post_haste',
  'Global Access': 'global_access',
  'IMEX': 'imex',
  'Landmark Global': 'landmark_global',
  'Landmark Global AU': 'landmark_global_au',
  'Landmark Global UK': 'landmark_global_uk',
  'Newgistics': 'newgistics',
  'Nobordist': 'nobordist',
  'NZ Post Domestic': 'courierpost',
  'NZ Post International': 'new_zealand_post_international',
  'OnTrac': 'ontrac',
  'Purolator Canada': 'purolator_ca',
  'Quantium': 'quantium',
  'RR Donnelley': 'rr_donnelley',
  'SEKO Omni-Channel Logistics': 'seko',
  'SEKO Omni-Channel Logistics UK': 'seko_uk',
  'Sendle': 'sendle',
  'Seven Senders': 'seven_senders',
  'StarTrack': 'star_track',
  'TNT Australia': 'tnt_australia',
  'Team Global Express IPEC': 'toll_ipec',
  'Team Global Express Priority': 'toll_priority',
  'wizmo': 'wizmo',
};

const getBestMatchingCarrierCode = (carrierName) => {
  let bestMatch = null;
  let highestMatchScore = 0;

  Object.keys(CARRIER_CODES).forEach((key) => {
    let matchScore = 0;
    const keyWords = key.toLowerCase().split(/\s+/);
    const nameWords = carrierName.toLowerCase().split(/\s+/);

    nameWords.forEach((word) => {
      if (keyWords.includes(word)) {
        matchScore++;
      }
    });

    if (matchScore > highestMatchScore) {
      highestMatchScore = matchScore;
      bestMatch = CARRIER_CODES[key];
    }
  });

  return bestMatch;
};

const fetchTrackingData = async (trackingNumber, carrierName) => {
  const carrierCode = getBestMatchingCarrierCode(carrierName);
  if (!carrierCode) return null;

  try {
    const response = await axios.get(`${process.env.REACT_APP_URL}/track/${carrierCode}/${trackingNumber}`);
    console.log("response", response.data);
    return response.data; // Updated to return response directly
  } catch (error) {
    console.log("error", error);
    console.error(`Failed to fetch tracking data for ${trackingNumber}`, error);
    return null;
  }
};

const getDeliveryData = (callback) => {
  return fetchAllDeliveriesData(async (deliveries) => {
    const updatedDeliveries = await Promise.all(
      deliveries.map(async (delivery) => {
        if (!delivery || !delivery.trackingID || !delivery.trackingPartner) {
          return delivery;
        }
        console.log("old delivery data", delivery.deliveryDate, delivery.deliveryStatus, delivery.updatedAt);
        const trackingData = await fetchTrackingData(delivery.trackingID, delivery.trackingPartner);
        if (!trackingData) return delivery;

        const firstEvent = trackingData.events?.[0] || {};

        let updatedTimestamp = delivery.updatedAt; // Keep the original value if valid

        if (firstEvent.occurred_at) {
          updatedTimestamp = new Date(firstEvent.occurred_at).toISOString(); // Convert API timestamp to string
        } else if (delivery.updatedAt?.seconds) {
          updatedTimestamp = new Date(delivery.updatedAt.seconds * 1000).toISOString(); // Convert Firebase timestamp
        } else if (typeof delivery.updatedAt !== "string") {
          updatedTimestamp = new Date().toISOString(); // Fallback to current date
        }

        const updatedDelivery = {
          ...delivery,
          deliveryDate: trackingData.actual_delivery_date ? trackingData.actual_delivery_date : delivery.deliveryDate,
          deliveryStatus: trackingData.status_description ? trackingData.status_description : delivery.deliveryStatus,
          updatedAt: updatedTimestamp
        };
        console.log("new delivery data", updatedDelivery.deliveryDate, updatedDelivery.deliveryStatus, updatedDelivery.updatedAt);
        await updateDeliveryWhenFetched(updatedDelivery, delivery.docId);
        return updatedDelivery;
      })
    );

    const formattedData = updatedDeliveries.map((delivery, index) => {
      if (!delivery) return null;

      const originalDate = delivery.deliveryDate ? new Date(delivery.deliveryDate) : null;
      let formattedDate = originalDate && !isNaN(originalDate.getTime())
        ? `${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')}-${originalDate.getFullYear()}`
        : 'TBD';

      return {
        vendorName: delivery.vendorName || '',
        vendorEmail: delivery.mailFrom || delivery.primaryEmail || '',
        uniqueId: delivery.docId,
        deliveryDate: formattedDate || '',
        spoId: delivery.spoId || delivery.spo || '',
        vpoId: delivery.vpoId || '',
        delQty: delivery.deliveryQty || '',
        saiQty: delivery.saiQty || '',
        trackingID: delivery.trackingID || '',
        trackingPartner: delivery.trackingPartner || '',
        status: delivery.deliveryStatus,
        updatedAt: delivery.updatedAt || '',
        comments : delivery.comments || '',
      };
    }).filter(Boolean);

    callback(formattedData);
  });
};

export default getDeliveryData;