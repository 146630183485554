import { useState, useEffect, useCallback, useRef } from 'react';
import { updateRFQ } from '../../database/set-rfq-data.js';
import { updateRFQArray } from '../../database/set-rfq-data.js';
import { fetchSingleRfqDataLive } from '../../database/fetch-rfq-data.js';
import { fetchAllVendorsData } from '../../database/fetch-vendor-data.js';
import { fetchEmailData } from '../../database/fetch-rfq-data.js';
import Loader from '../loader/loader.js';
import { v4 as uuidv4 } from 'uuid';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { successAlert, errorAlert } from '../alerts.js';
import { storeFilePackets } from '../../storage/store-files-rfq.js';
import DOMPurify from 'dompurify';
import { Editor } from '@tinymce/tinymce-react';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import * as XLSX from 'xlsx';
import PreviewFileModal from '../preview-file-modal.js';
import { fetchAllEmailTemplates } from '../../database/fetch-email-template.js';
import updateTablePref from '../../database/set-table-pref.js';
import fetchTablePref from '../../database/fetch-table-pref.js';

function ViewRfqComponent({ openEditModal, setOpenEditModal, docId }) {
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [headerStatement, setHeaderStatement] = useState('');
  const [currentTab, setCurrentTab] = useState('RFQ');
  const [isEditable, setIsEditable] = useState(false);
  const [isContactDetailsVisible, setContactDetailsVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [vendorsData, setVendorsData] = useState([]);
  const [mailData, setMailData] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [modalText, setModalText] = useState('');
  const [
    isSendRequestQuoteEmailModalOpen,
    setIsSendRequestQuoteEmailModalOpen,
  ] = useState(false);
  const [isAddNewBidModalOpen, setIsAddNewBidModalOpen] = useState(false);
  const [isSendForReviewModalOpen, setIsSendForReviewModalOpen] =
    useState(false);
  const [isSendRFQPacketEmailModalOpen, setIsSendRFQPacketEmailModalOpen] =
    useState(false);

  const onSelectionItemsChange = useCallback((config) => {
    let dataArray = [];
    let selectedObject = {};
    if (config.selected === true) {
      dataArray = config.data;
      dataArray.forEach((item) => {
        selectedObject[item.itemId] = item;
      });
      setSelectedItems(selectedObject);
    } else {
      setSelectedItems(config.selected);
    }
  }, []);

  const onSelectionVendorsChange = useCallback((config) => {
    // console.log(config);
    let dataArray = [];
    let selectedObject = {};
    if (config.selected === true) {
      dataArray = config.data;
      dataArray.forEach((vendor) => {
        selectedObject[vendor.uniqueId] = vendor;
      });
      // console.log(selectedObject);
      setSelectedVendors(selectedObject);
    } else {
      setSelectedVendors(config.selected);
    }
  }, []);
  const [rfqNo, setRfqNo] = useState('');
  const [agency, setAgency] = useState('');
  const [saiQuotesData, setSaiQuotesData] = useState([]);
  const [releaseDate, setReleaseDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [address, setAddress] = useState('');
  const [primaryContactName, setPrimaryContactName] = useState('');
  const [secondaryContactName, setSecondaryContactName] = useState('');
  const [primaryPhone, setPrimaryPhone] = useState('');
  const [secondaryPhone, setSecondaryPhone] = useState('');
  const [primaryEmail, setPrimaryEmail] = useState('');
  const [secondaryEmail, setSecondaryEmail] = useState('');
  const [isPrimaryEmailValid, setIsPrimaryEmailValid] = useState(true);
  const [isSecondaryEmailValid, setIsSecondaryEmailValid] = useState(true);
  const [comments, setComments] = useState('');
  const [items, setItems] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [viewBids, setViewBids] = useState([]);
  const [selectedNewBidItemId, setSelectedNewBidItemId] = useState(null);
  const [selectedNewBidVendorId, setSelectedNewBidVendorId] = useState(null);
  const [vendorAddQty, setVendorAddQty] = useState(0);
  const [vendorAddPrice, setVendorAddPrice] = useState(0);
  const [vendorAddSAndH, setVendorAddSAndH] = useState(0);
  const [vendorAddTax, setVendorAddTax] = useState(0);
  const [vendorAddTotal, setVendorAddTotal] = useState(0);
  const [rfqPacketData, setRfqPacketData] = useState([]);
  const [packetsAttachments, setPacketsAttachments] = useState([]);
  const [rfqEmail, setRfqEmail] = useState('');
  const [viewBidsData, setViewBidsData] = useState([]);
  const [viewRfqPackets, setViewRfqPackets] = useState([]);

  const [tempRQMailContent, setTempRQMailContent] = useState('');
  const [finalRQMailContent, setfinalRQMailContent] = useState([]);
  const [mailRQSubject, setMailRQSubject] = useState('');
  const [emailsRQ, setEmailsRQ] = useState([]);
  const [emails, setEmails] = useState([]);
  const [tempMailContent, setTempMailContent] = useState('');
  const [finalMail, setFinalMail] = useState([]);
  const [mailSubject, setMailSubject] = useState('');
  const [attachmentRows, setAttachmentRows] = useState([]);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [emailRQTemplates, setEmailRQTemplates] = useState([]);
  const [previewFileUrl, setPreviewFileUrl] = useState();
  const [openFilePreviewModal, setOpenFilePreviewModal] = useState(false);
  const [tablePreferences, setTablePreferences] = useState({});
  const [selectedRFQ, setSelectedRFQ] = useState([]);

  useEffect(() => {
    let unsubscribe;

    const formatDate = (date) =>
      date
        ? `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
            .getDate()
            .toString()
            .padStart(2, '0')}/${date.getFullYear()}`
        : '';

    if (docId) {
      unsubscribe = fetchSingleRfqDataLive(docId, (rfqData) => {
        if (rfqData) {
          console.log('rfqData', rfqData);

          // Convert 'releaseDate' string to a Date object
          const releaseDate = rfqData.releaseDate
            ? new Date(rfqData.releaseDate)
            : null;

          // Convert 'dueDate' string to a Date object
          const dueDate = rfqData.dueDate ? new Date(rfqData.dueDate) : null;

          rfqData.primaryContactName = rfqData.primaryContactName.replace(
            /@.*$/,
            ''
          );
          if (rfqData.primaryEmail.endsWith('ca.gov')) {
            if (!rfqData.primaryPhone) {
              rfqData.primaryPhone = '1234567890';
            }
            if (!rfqData.address && !rfqData.deliveryAddress) {
              rfqData.address = 'California';
              rfqData.deliveryAddress = 'California';
            }
          }

          const formatDateForDisplay = (isoDateString) => {
            if (!isoDateString) return '';
            const d = new Date(isoDateString);
            const day = d.getDate().toString().padStart(2, '0');
            const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
            const year = d.getFullYear();
            return `${month}/${day}/${year}`;
          };

          const formatDateForInput = (isoDateString) => {
            if (!isoDateString) return '';
            const d = new Date(isoDateString);
            const year = d.getFullYear();
            const month = (d.getMonth() + 1).toString().padStart(2, '0');
            const day = d.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
          };

          // Use ISO string for internal logic or storage
          const dueDateISO = dueDate ? new Date(dueDate).toISOString() : '';

          const dueDateParts = rfqData?.dueDate?.split('-'); // Split the date string into [year, month, day]
          const formattedDueDate = `${dueDateParts[1]}-${dueDateParts[2]}-${dueDateParts[0]}`; // Rearrange to MM-DD-YYYY

          const headerStatement = `${rfqData.rfqNo} - ${rfqData.agency} Due On: ${formattedDueDate}`;

          setHeaderStatement(
            `${rfqData.rfqNo} - ${rfqData.agency} Due On: ${formattedDueDate}`
          );

          setRfqNo(rfqData.rfqNo || '');
          setAgency(rfqData.agency || '');
          setReleaseDate(rfqData.releaseDate ? rfqData.releaseDate : '');
          setDueDate(rfqData.dueDate ? rfqData.dueDate : '');
          setAddress(rfqData.address || rfqData.deliveryAddress || '');
          setPrimaryContactName(rfqData.primaryContactName || '');
          setSecondaryContactName(rfqData.secondaryContactName || '');
          setPrimaryPhone(rfqData.primaryPhone || '');
          setSecondaryPhone(rfqData.secondaryPhone || '');
          setPrimaryEmail(rfqData.primaryEmail || '');
          setSecondaryEmail(rfqData.secondaryEmail || '');
          setComments(rfqData.comments || '');

          let updatedItems = rfqData?.items
            ?.map((item) => {
              const cleanedItem = Object.fromEntries(
                Object.entries(item).map(([key, value]) => {
                  const cleanedKey = key
                    .replace(/descrip�on/, 'description')
                    .replace(/quantity/i, 'qty') // Normalize 'quantity' to 'qty'
                    .trim();

                  return [cleanedKey, value];
                })
              );
              return cleanedItem;
            })
            .filter((item) => item.qty > 0)
            .map((item) => {
              return {
                ...item,
                uniqueId: item.itemId,
              };
            });

          console.log(updatedItems, 'updatedItems');

          setItems(updatedItems || []);

          let attachments = rfqData.attachments || [];
          let packetsAttachments = rfqData.packetsAttachments || [];

          setAttachments([...attachments, ...packetsAttachments]);
          setAttachmentRows([...attachments, ...packetsAttachments]);
          setViewBids(rfqData.quotes || []);
          setPacketsAttachments(rfqData.packetsAttachments || []);
          setRfqEmail(rfqData.rfqEmail || '');
          setAttachmentsData(rfqData.attachmentsData || []);
          setEmails([rfqData.primaryEmail || '', rfqData.secondaryEmail || '']);
          setMailSubject(
            `Please find the RFQ Packet for RFQ: ${rfqData.rfqNo} - ${
              rfqData.agency
            } Due On: ${formatDate(dueDate)}`
          );
        }
      });
    }

    fetchAllEmailTemplates((templates) => {
      const filteredTemplates = templates.filter(
        (template) => template.mailType === 'rfq'
      );
      const filteredRQTemplates = templates.filter(
        (template) => template.mailType === 'rfq_rq'
      );
      setEmailTemplates(filteredTemplates);
      setEmailRQTemplates(filteredRQTemplates);
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [docId]);

  useEffect(() => {
    const unsubscribe = fetchAllVendorsData((vendors) => {
      let updatedVendors = vendors.map((vendor) => {
        return {
          ...vendor,
          uniqueId: vendor.vendorId,
        };
      });
      // // console.log(updatedVendors);
      setVendorsData(updatedVendors);
    });

    fetchTablePref('RfqItems')
      .then((tableData) => {
        if (tableData)
          setTablePreferences((prevData) => ({
            ...prevData,
            RfqItems: tableData,
          }));
      })
      .catch((err) => console.log(err));

    fetchTablePref('RfqAttachments')
      .then((tableData) => {
        if (tableData)
          setTablePreferences((prevData) => ({
            ...prevData,
            RfqAttachments: tableData,
          }));
      })
      .catch((err) => console.log(err));

    fetchTablePref('RfqRequestQuoteItems')
      .then((tableData) => {
        if (tableData)
          setTablePreferences((prevData) => ({
            ...prevData,
            RfqRequestQuoteItems: tableData,
          }));
      })
      .catch((err) => console.log(err));

    fetchTablePref('RfqRequestQuoteVendors')
      .then((tableData) => {
        if (tableData)
          setTablePreferences((prevData) => ({
            ...prevData,
            RfqRequestQuoteVendors: tableData,
          }));
      })
      .catch((err) => console.log(err));

    fetchTablePref('RfqViewBids')
      .then((tableData) => {
        if (tableData)
          setTablePreferences((prevData) => ({
            ...prevData,
            RfqViewBids: tableData,
          }));
      })
      .catch((err) => console.log(err));

    fetchTablePref('RfqSaiQuotes')
      .then((tableData) => {
        if (tableData)
          setTablePreferences((prevData) => ({
            ...prevData,
            RfqSaiQuotes: tableData,
          }));
      })
      .catch((err) => console.log(err));
    fetchTablePref('RfqPackets')
      .then((tableData) => {
        if (tableData)
          setTablePreferences((prevData) => ({
            ...prevData,
            RfqPackets: tableData,
          }));
      })
      .catch((err) => console.log(err));

    // Clean up function
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Extract emails from selected vendors
    const initialEmails = Object.values(selectedVendors)?.map(
      (vendor) => vendor?.primaryEmail
    );
    setEmailsRQ(initialEmails);
  }, [selectedVendors]);

  const cleanAttachmentsData = () => {
    let cleanData = '';
    if (attachmentsData) {
      attachmentsData.forEach((attachment) => {
        // Add the attachment name in bold
        cleanData += `<strong>${attachment.name}</strong><br>`;

        // Add the sanitized content, replacing newlines with <br> tags
        cleanData += DOMPurify.sanitize(attachment.data, {
          USE_PROFILES: { html: true },
        }).replace(/\n/g, '<br>');

        // Add a separator line
        cleanData +=
          '<br>------------------------------------------------------------------------------<br>';
      });
    }
    return cleanData;
  };

  const [mappingModalOpen, setMappingModalOpen] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [headerRow, setHeaderRow] = useState();
  const [fileData, setFileData] = useState();
  const [selectedMapping, setSelectedMapping] = useState({});
  const excelFileInputRef = useRef(null);

  const cleanRfqEmail = DOMPurify.sanitize(rfqEmail, {
    USE_PROFILES: { html: true },
  }).replace(/[\r\n]+/g, ' '); // Replace with a space

  useEffect(() => {
    const total =
      (parseFloat(vendorAddQty) || 0) *
        (parseFloat(vendorAddPrice) || 0) *
        (1 + (parseFloat(vendorAddTax) || 0) / 100) +
      (parseFloat(vendorAddSAndH) || 0);
    setVendorAddTotal(total.toFixed(2));
  }, [vendorAddQty, vendorAddPrice, vendorAddSAndH, vendorAddTax]);

  /**
   * The `handlePhoneNumberChange` function formats a phone number input by adding parentheses, spaces,
   * and dashes in the appropriate places.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. In this case, it is likely an event object related to a change event on an input field.
   */
  const handlePhoneNumberChange = (event, setPhone) => {
    let input = event.target.value.replace(/\D/g, '');

    if (input.length > 10) {
      input = input.substring(0, 10);
    }

    let size = input.length;

    if (size < 4) {
      input = '(' + input;
    } else if (size < 7) {
      input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6);
    } else {
      input =
        '(' +
        input.substring(0, 3) +
        ') ' +
        input.substring(3, 6) +
        '-' +
        input.substring(6, 10);
    }

    event.target.value = input;
    setPhone(input);
  };

  /**
   * The below code defines two functions, handlePrimaryEmailChange and handleSecondaryEmailChange, which
   * handle changes to the primary and secondary email inputs respectively and validate the email format
   * using a regular expression.
   * @param event - The `event` parameter is an object that represents the event that occurred. In this
   * case, it is an event object related to the email input field. It contains information about the
   * event, such as the target element (the input field), the value of the input field, and other
   * properties and methods
   */
  const handlePrimaryEmailChange = (event) => {
    setPrimaryEmail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsPrimaryEmailValid(emailRegex.test(event.target.value));
  };

  const handleSecondaryEmailChange = (event) => {
    setSecondaryEmail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsSecondaryEmailValid(emailRegex.test(event.target.value));
  };

  /**
   * The function `validatePageOne` checks if all required fields on a page are filled and returns the
   * name of the first unfilled field, or null if all fields are filled.
   * @returns The function `validatePageOne` returns the name of the first unfilled field from the
   * `requiredFields` array. If all fields are filled, it returns `null`.
   */
  const validateFields = () => {
    // List all required fields with their names
    const requiredFields = [
      { name: 'RFQ No', value: rfqNo },
      { name: 'Agency', value: agency },
      { name: 'Release Date', value: releaseDate },
      { name: 'Due Date', value: dueDate },
      { name: 'Address', value: address },
      { name: 'Primary Contact Name', value: primaryContactName },
      { name: 'Primary Phone', value: primaryPhone },
      { name: 'Primary Email', value: primaryEmail },
    ];

    // Find the first field that is not filled
    const unfilledField = requiredFields.find(
      (field) => field.value === null || field.value === ''
    );

    // Return the name of the unfilled field, or null if all fields are filled
    return unfilledField ? unfilledField.name : null;
  };

  const saveRfq = async () => {
    const unfilledField = validateFields();
    if (unfilledField) {
      alert(`Please fill the ${unfilledField} field.`);
      return;
    }

    const rfqData = {
      rfqNo,
      agency,
      releaseDate,
      dueDate,
      address,
      primaryContactName,
      primaryPhone,
      secondaryContactName,
      secondaryPhone,
      primaryEmail,
      secondaryEmail,
      comments,
    };

    try {
      await updateRFQ(docId, rfqData);
      setIsEditable(false);
    } catch (error) {
      console.error('Failed to save RFQ:', error);
    }
  };
  const gridStyle = { minHeight: 400 };

  const itemsGridColumns = [
    {
      name: 'clin',
      header: 'CLIN',
      minWidth: 100,
      defaultWidth: tablePreferences?.RfqItems?.clin || 100,
    },
    {
      name: 'itemName',
      header: 'Item Name',
      minWidth: 250,
      defaultWidth: tablePreferences?.RfqItems?.itemName || 250,
    },
    {
      name: 'category',
      header: 'Category',
      minWidth: 200,
      defaultWidth: tablePreferences?.RfqItems?.category || 200,
    },
    {
      name: 'qty',
      header: 'Qty',
      minWidth: 100,
      defaultWidth: tablePreferences?.RfqItems?.qty || 100,
    },
    {
      name: 'unit',
      header: 'Unit',
      minWidth: 100,
      defaultWidth: tablePreferences?.RfqItems?.unit || 100,
    },
    {
      name: 'description',
      header: 'Description',
      minWidth: 300,
      defaultWidth: tablePreferences?.RfqItems?.description || 300,
    },
    {
      name: 'deliveryAddress',
      header: 'Delivery Address',
      minWidth: 300,
      defaultWidth: tablePreferences?.RfqItems?.deliveryAddress || 300,
    },
    {
      name: 'comment',
      header: 'Comments',
      minWidth: 300,
      defaultWidth: tablePreferences?.RfqItems?.comment || 300,
    },
  ];

  const orderColumns = (columns, order) => {
    if (!order) return columns;

    const orderedColumns = [];
    order.forEach((columnName) => {
      const column = columns.find((col) => col.name === columnName);
      if (column) {
        orderedColumns.push(column);
      }
    });

    // Add any remaining columns that are not specified in the order
    columns.forEach((column) => {
      if (!order.includes(column.name)) {
        orderedColumns.push(column);
      }
    });

    return orderedColumns;
  };

  const orderedItemsGridColumns = orderColumns(
    itemsGridColumns,
    tablePreferences?.RfqItems?.order
  );

  const previewRenderer = ({ data }) => {
    const handleDownloadClick = (event) => {
      const docId = event.currentTarget.getAttribute('data-url');
      window.open(docId, '_blank');
    };

    const handlePreviewClick = (event) => {
      const docId = event.currentTarget.getAttribute('data-url');
      setPreviewFileUrl(docId);
      setOpenFilePreviewModal(true);
    };

    return (
      <div>
        <button
          className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          data-url={data.data.attachmentUrl}
          onClick={handlePreviewClick}
        >
          Preview
        </button>
        <button
          className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          data-url={data.data.attachmentUrl}
          onClick={handleDownloadClick}
        >
          Download
        </button>
      </div>
    );
  };

  const attachmentsGridColumns = [
    {
      name: 'name',
      header: 'File Name',
      minWidth: 250,
      defaultWidth: tablePreferences?.RfqAttachments?.name || 250,
      defaultFlex: 1,
      editable: true,
    },
    {
      name: 'preview',
      header: 'Preview',
      minWidth: 150,
      defaultWidth: tablePreferences?.RfqAttachments?.preview || 150,
      render: (data) => previewRenderer({ data }),
      headerAlign: 'center',
      defaultFlex: 1,
      textAlign: 'center',
    },
  ];

  const vendorsGridColumns = [
    {
      name: 'vendorName',
      header: 'Name',

      minWidth: 250,
      defaultWidth: tablePreferences?.RfqRequestQuoteVendors?.vendorName || 250,
    },
    {
      name: 'primaryEmail',
      header: 'Email',

      minWidth: 250,
      defaultWidth:
        tablePreferences?.RfqRequestQuoteVendors?.primaryEmail || 250,
    },
    {
      name: 'primaryPSCCategory',
      header: 'Category',

      minWidth: 150,
      defaultWidth:
        tablePreferences?.RfqRequestQuoteVendors?.primaryPSCCategory || 150,
    },
    {
      name: 'city',
      header: 'City',

      minWidth: 100,
      defaultWidth: tablePreferences?.RfqRequestQuoteVendors?.city || 100,
    },
    {
      name: 'state',
      header: 'State',

      minWidth: 100,
      defaultWidth: tablePreferences?.RfqRequestQuoteVendors?.state || 100,
    },
  ];

  const orderedVendorsGridColumns = orderColumns(
    vendorsGridColumns,
    tablePreferences?.RfqRequestQuoteVendors?.order
  );

  const vendorsGridFilterValue = [
    { name: 'vendorName', operator: 'contains', type: 'string', value: '' },
    {
      name: 'primaryPSCCategory',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    { name: 'city', operator: 'contains', type: 'string', value: '' },
    { name: 'state', operator: 'contains', type: 'string', value: '' },
    { name: 'primaryEmail', operator: 'contains', type: 'string', value: '' },
  ];

  const vendorsGridData = useCallback(() => {
    return vendorsData.map((vendor) => ({
      uniqueId: vendor.vendorId,
      vendorName: vendor.vendorName,
      primaryPSCCategory: vendor.primaryPSCCategory,
      city: vendor.city,
      state: vendor.state,
      primaryEmail: vendor.primaryEmail,
    }));
  }, [vendorsData]);

  const itemsGridRequestQuoteColumns = [
    {
      name: 'clin',
      header: 'CLIN',
      minWidth: 50,
      defaultWidth: tablePreferences?.RfqRequestQuoteItems?.clin || 50,
      type: 'text',
    },
    {
      name: 'itemName',
      header: 'Item Name',
      minWidth: 200,
      defaultWidth: tablePreferences?.RfqRequestQuoteItems?.itemName || 200,
    },
    {
      name: 'category',
      header: 'Category',
      minWidth: 200,
      defaultWidth: tablePreferences?.RfqRequestQuoteItems?.category || 200,
    },
    {
      name: 'qty',
      header: 'Qty',
      minWidth: 100,
      defaultWidth: tablePreferences?.RfqRequestQuoteItems?.qty || 100,
    },
    {
      name: 'unit',
      header: 'Unit',
      minWidth: 100,
      defaultWidth: tablePreferences?.RfqRequestQuoteItems?.unit || 100,
    },
    {
      name: 'description',
      header: 'Description',
      minWidth: 300,
      defaultWidth: tablePreferences?.RfqRequestQuoteItems?.description || 300,
    },
    {
      name: 'deliveryAddress',
      header: 'Delivery Address',
      minWidth: 300,
      defaultWidth:
        tablePreferences?.RfqRequestQuoteItems?.deliveryAddress || 300,
    },
    {
      name: 'comment',
      header: 'Comments',
      minWidth: 300,
      defaultWidth: tablePreferences?.RfqRequestQuoteItems?.comment || 300,
    },
  ];

  const orderedItemsGridRequestQuoteColumns = orderColumns(
    itemsGridRequestQuoteColumns,
    tablePreferences?.RfqRequestQuoteItems?.order
  );

  const itemsGridRequestQuoteFilterValue = [
    { name: 'clin', operator: 'contains', type: 'string', value: '' },
    { name: 'itemName', operator: 'contains', type: 'string', value: '' },
    { name: 'category', operator: 'contains', type: 'string', value: '' },
    { name: 'qty', operator: 'contains', type: 'string', value: '' },
    { name: 'unit', operator: 'contains', type: 'string', value: '' },
    { name: 'description', operator: 'contains', type: 'string', value: '' },
    {
      name: 'deliveryAddress',
      operator: 'contains',
      type: 'string',
      value: '',
    },
  ];

  const itemsGridRequestQuotesData = useCallback(() => {
    return items?.map((item) => ({
      uniqueId: item.itemId,
      clin: item.clin,
      itemName: item.itemName,
      category: item.category,
      qty: item.qty,
      description: item.description,
      deliveryAddress: item.deliveryAddress,
    }));
  }, [items]);

  const attachmentsGridData = useCallback(() => {
    return attachments.map((attachment) => ({
      uniqueId: attachment.attachmentId,
      name: attachment.name,
      attachmentUrl: attachment.attachmentUrl,
    }));
  }, [attachments]);

  const itemsGridData = useCallback(() => {
    return items.map((item) => ({
      uniqueId: item.itemId || item.itemIdentifier || item.id,
      clin: item.clin,
      itemName: item.itemName,
      category: item.category,
      qty: item.qty,
      unit: item.unit,
      description: item.description,
      deliveryAddress: item.deliveryAddress,
      comment: item.comment,
    }));
  }, [items]);

  const handleSelectChange = (headerName, selectedParameter) => {
    const alreadySelectedHeader = Object.entries(selectedMapping).find(
      ([header, parameter]) =>
        header !== headerName && parameter === selectedParameter
    );

    if (alreadySelectedHeader) {
      alert(
        `The parameter "${selectedParameter}" is already selected for the header "${alreadySelectedHeader[0]}". Please choose a different parameter.`
      );
      return;
    }

    setSelectedMapping((prevMapping) => ({
      ...prevMapping,
      [headerName]: selectedParameter,
    }));
  };

  const handleAddNewItemExcel = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const fileDataT = await readExcelFile(file);
        // console.log(fileDataT);
        setFileData(fileDataT);

        const { headers: headersTemp, headerRow: detectedHeaderRow } =
          extractHeaders(fileDataT);
        setHeaders(headersTemp);
        setHeaderRow(detectedHeaderRow); // Save detected header row to state
        setMappingModalOpen(true);
      } catch (error) {
        console.error('Error reading or parsing the file', error);
      }
    }
  };

  const processExcelFile = () => {
    const parsedItems = parseExcelData(fileData, selectedMapping, headerRow);

    parsedItems.forEach((item, index) => {
      if (!item.unit || item.unit.trim() === '') {
        item.unit = 'EA';
      }

      if (
        !item.clin ||
        (typeof item.clin === 'string' && item.clin.trim() === '')
      ) {
        item.clin = (index + 1).toString(); // Start from 1 instead of 0
      }
      // Pad clin to always have three digits
      item.clin = String(item.clin).padStart(3, '0');

      if (!item.deliveryAddress || item.deliveryAddress.trim() === '') {
        item.deliveryAddress = address;
      }
    });

    // Update items state
    setItems((prevItems) => {
      const updatedItems = [...prevItems, ...parsedItems];
      updateRFQArray('items', updatedItems, docId);
      setSelectedMapping({});
      setHeaders([]);
      return updatedItems;
    });

    // Reset the file input
    if (excelFileInputRef.current) {
      excelFileInputRef.current.value = null;
    }
  };

  const extractHeaders = (fileData) => {
    const workbook = XLSX.read(fileData, { type: 'binary' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    // Convert the sheet to JSON to analyze rows
    const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    // Determine the header row by finding the row with the most non-empty cells
    let headerRow = 0;
    let maxNonEmptyCells = 0;

    for (let i = 0; i < rows.length; i++) {
      const nonEmptyCells = rows[i].filter(
        (cell) => cell !== null && cell !== undefined && cell !== ''
      ).length;
      if (nonEmptyCells > maxNonEmptyCells) {
        maxNonEmptyCells = nonEmptyCells;
        headerRow = i;
      }
    }

    const headers = rows[headerRow];
    // console.log('Detected headers:', headers);
    return { headers, headerRow };
  };

  const readExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (e) => {
        reject(new Error('Error reading the file.'));
      };
      reader.readAsBinaryString(file);
    });
  };

  const parseExcelData = (fileData, mapping, headerRow) => {
    const workbook = XLSX.read(fileData, { type: 'binary' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    // Get the headers from the detected row
    const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[
      headerRow
    ];

    // Get the data rows starting from the row after the headers
    const dataRows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    dataRows.splice(0, headerRow + 1);

    return dataRows.map((row) => {
      const mappedItem = {};
      Object.keys(mapping).forEach((key) => {
        const columnIndex = headers.indexOf(mapping[key]);
        if (columnIndex !== -1) {
          mappedItem[key] = row[columnIndex];
        }
      });
      return {
        itemId: uuidv4(),
        ...mappedItem,
      };
    });
  };

  const viewBidsColumns = [
    {
      name: 'clin',
      header: 'CLIN',
      minWidth: 150,
      defaultWidth: tablePreferences?.RfqViewBids?.clin || 150,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
    },
    {
      name: 'name',
      header: 'Item Name',
      minWidth: 250,
      defaultWidth: tablePreferences?.RfqViewBids?.name || 250,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
    },
    {
      name: 'deliveryAddress',
      header: 'Delivery Address',
      minWidth: 250,
      defaultWidth: tablePreferences?.RfqViewBids?.deliveryAddress || 250,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
    },
    {
      name: 'qty',
      header: 'Required Qty',
      minWidth: 100,
      defaultWidth: tablePreferences?.RfqViewBids?.qty || 100,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
    },
    {
      name: 'unit',
      header: 'Unit',
      minWidth: 100,
      defaultWidth: tablePreferences?.RfqViewBids?.unit || 100,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
    },
    {
      name: 'vQty',
      header: 'Vendor Qty',
      minWidth: 100,
      defaultWidth: tablePreferences?.RfqViewBids?.vQty || 100,
      editable: true,
      render: ({ value, data }) => (
        <span
          className={
            data.status ? 'text-green-500' : 'text-red-500 line-through'
          }
        >
          {value}
        </span>
      ),
    },
    {
      name: 'price',
      header: 'Price',
      minWidth: 100,
      defaultWidth: tablePreferences?.RfqViewBids?.price || 100,
      editable: true,
      render: ({ value, data }) => {
        const formattedValue = value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return (
          <span
            className={
              data.status ? 'text-green-500' : 'text-red-500 line-through'
            }
          >{`$${formattedValue}`}</span>
        );
      },
    },
    {
      name: 'sAndH',
      header: 'S&H',
      minWidth: 100,
      defaultWidth: tablePreferences?.RfqViewBids?.sAndH || 100,
      editable: true,
      render: ({ value, data }) => {
        const formattedValue = value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return (
          <span
            className={
              data.status ? 'text-green-500' : 'text-red-500 line-through'
            }
          >{`$${formattedValue}`}</span>
        );
      },
    },
    {
      name: 'tax',
      header: 'Tax',
      minWidth: 100,
      defaultWidth: tablePreferences?.RfqViewBids?.tax || 100,
      editable: true,
      render: ({ value, data }) => (
        <span
          className={
            data.status ? 'text-green-500' : 'text-red-500 line-through'
          }
        >{`${value}%`}</span>
      ),
    },
    {
      name: 'total',
      header: 'Total',
      minWidth: 150,
      defaultWidth: tablePreferences?.RfqViewBids?.total || 150,
      render: ({ value, data }) => {
        const formattedValue = value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return (
          <span
            className={
              data.status ? 'text-green-500' : 'text-red-500 line-through'
            }
          >{`$${formattedValue}`}</span>
        );
      },
    },
    {
      name: 'vendorName',
      header: 'Vendor Name',
      minWidth: 250,
      defaultWidth: tablePreferences?.RfqViewBids?.vendorName || 250,
      render: ({ value, data }) => (
        <a
          href={`mailto:${data.vendorEmail}`}
          className={data.status ? 'underline text-blue-500' : 'line-through'}
        >
          {value}
        </a>
      ),
    },
    {
      name: 'status',
      header: 'Decision',
      minWidth: 150,
      defaultWidth: tablePreferences?.RfqViewBids?.status || 150,
      render: ({ value, data }) => {
        const handleClick = () => {
          // Toggle the status
          const newStatus = !data.status;
          // console.log(value);
          var indexNo = -1;
          viewBids.forEach((item, index) => {
            if (item.uniqueId === data.uniqueId) {
              indexNo = index;
            }
          });

          viewBids[indexNo]['status'] = newStatus;
          // console.log(viewBids);
          updateRFQArray('quotes', viewBids, docId);
        };

        return (
          <button
            onClick={handleClick}
            className={`px-2 py-1 rounded w-full text-white ${
              value ? 'bg-red-500' : 'bg-green-500'
            }`}
          >
            {value ? 'Withdraw' : 'Accept'}
          </button>
        );
      },
    },
    {
      name: 'comment',
      header: 'Comments',
      minWidth: 200,
      defaultWidth: tablePreferences?.RfqViewBids?.comment || 200,
      editable: true,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
    },
  ];

  const orderedViewBidsColumns = orderColumns(
    viewBidsColumns,
    tablePreferences?.RfqViewBids?.order
  );

  const viewBidsFilterValue = [
    { name: 'CLIN', type: 'string', operator: 'contains', value: '' },
    { name: 'name', operator: 'contains', type: 'string', value: '' },
    {
      name: 'deliveryAddress',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    { name: 'qty', operator: 'gte', type: 'number', value: '' },
    { name: 'unit', operator: 'contains', type: 'string', value: '' },
    { name: 'vQty', operator: 'gte', type: 'number', value: '' },
    { name: 'price', operator: 'gte', type: 'number', value: '' },
    { name: 'sAndH', operator: 'gte', type: 'number', value: '' },
    { name: 'tax', operator: 'contains', type: 'string', value: '' },
    { name: 'total', operator: 'gte', type: 'number', value: '' },
    { name: 'vendorName', operator: 'contains', type: 'string', value: '' },
    { name: 'status', operator: 'contains', type: 'string', value: '' },
  ];

  const viewBidsGridData = useCallback(() => {
    console.log(viewBids, 'viewBids');
    const sortedViewBids = viewBids.sort((a, b) => b.status - a.status);

    const formattedBids = sortedViewBids.map((viewBid) => ({
      uniqueId: viewBid.uniqueId,
      clin: viewBid.clin,
      name: viewBid.itemName,
      qty: viewBid.qty,
      unit: viewBid.unit,
      description: viewBid.description,
      deliveryAddress: viewBid.deliveryAddress,
      vendorName: viewBid.vendorName,
      vendorEmail: viewBid.primaryEmail,
      status: viewBid.status,
      comment: viewBid.comment,
      vQty: parseFloat(viewBid.vQty || 0).toFixed(2),
      price: parseFloat(viewBid.price || 0).toFixed(2),
      sAndH: parseFloat(viewBid.sAndH || 0).toFixed(2),
      tax: parseFloat(viewBid.tax || 0).toFixed(2),
      total: parseFloat(
        (parseFloat(viewBid.vQty) || 0) *
          (parseFloat(viewBid.price) || 0) *
          (1 + (parseFloat(viewBid.tax) || 0) / 100) +
          (parseFloat(viewBid.sAndH) || 0)
      ).toFixed(2),
    }));
    setViewBidsData(formattedBids);
  }, [viewBids]);
  useEffect(() => {
    viewBidsGridData();
  }, [viewBidsGridData]);

  const onEditCompleteViewBidsGrid = (newData) => {
    var indexNo = -1;
    // // console.log(newData);
    viewBids.forEach((item, index) => {
      if (item.uniqueId === newData.rowId) {
        indexNo = index;
      }
    });
    const columnId = newData.columnId;
    viewBids[indexNo][columnId] = newData.value;
    // console.log(viewBids);
    updateRFQArray('quotes', viewBids, docId);
  };

  const saiQuotesColumns = [
    {
      name: 'clin',
      header: 'CLIN',
      minWidth: 150,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
      defaultWidth: tablePreferences?.RfqSaiQuotes?.clin || 150,
    },
    {
      name: 'name',
      header: 'Item Name',
      minWidth: 250,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
      defaultWidth: tablePreferences?.RfqSaiQuotes?.name || 250,
    },
    {
      name: 'deliveryAddress',
      header: 'Delivery Address',
      minWidth: 250,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
      defaultWidth: tablePreferences?.RfqSaiQuotes?.deliveryAddress || 250,
    },
    {
      name: 'qty',
      header: 'Required Qty',
      minWidth: 100,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
      defaultWidth: tablePreferences?.RfqSaiQuotes?.qty || 100,
    },
    {
      name: 'unit',
      header: 'Unit',
      minWidth: 100,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
      defaultWidth: tablePreferences?.RfqSaiQuotes?.unit || 100,
    },
    {
      name: 'vQty',
      header: 'Vendor Qty',
      minWidth: 100,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
      defaultWidth: tablePreferences?.RfqSaiQuotes?.vQty || 100,
    },
    {
      name: 'price',
      header: 'Price',
      minWidth: 100,
      render: ({ value, data }) => {
        const formattedValue = value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return (
          <span
            className={data.status ? '' : 'line-through'}
          >{`$${formattedValue}`}</span>
        );
      },
      defaultWidth: tablePreferences?.RfqSaiQuotes?.price || 100,
    },
    {
      name: 'sAndH',
      header: 'S&H',
      minWidth: 100,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{`$${value}`}</span>
      ),
      defaultWidth: tablePreferences?.RfqSaiQuotes?.sAndH || 100,
    },
    {
      name: 'tax',
      header: 'Tax',
      minWidth: 100,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{`${value}%`}</span>
      ),
      defaultWidth: tablePreferences?.RfqSaiQuotes?.tax || 100,
    },
    {
      name: 'total',
      header: 'Total',
      minWidth: 150,
      render: ({ value, data }) => {
        const formattedValue = value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return (
          <span
            className={data.status ? '' : 'line-through'}
          >{`$${formattedValue}`}</span>
        );
      },
      defaultWidth: tablePreferences?.RfqSaiQuotes?.total || 150,
    },
    {
      name: 'saiQty',
      header: 'Y Qty',
      minWidth: 100,
      editable: true,
      render: ({ value, data }) => (
        <span
          className={
            data.status ? 'text-green-500' : 'text-red-500 line-through'
          }
        >{`${value}`}</span>
      ),
      defaultWidth: tablePreferences?.RfqSaiQuotes?.saiQty || 100,
    },
    {
      name: 'margin',
      header: 'Margin',
      minWidth: 100,
      editable: true,
      render: ({ value, data }) => (
        <span
          className={
            data.status ? 'text-green-500' : 'text-red-500 line-through'
          }
        >{`${value}%`}</span>
      ),
      defaultWidth: tablePreferences?.RfqSaiQuotes?.margin || 100,
    },
    {
      name: 'gAndA',
      header: 'G&A',
      minWidth: 100,
      editable: true,
      render: ({ value, data }) => (
        <span
          className={
            data.status ? 'text-green-500' : 'text-red-500 line-through'
          }
        >{`$${value}`}</span>
      ),
      defaultWidth: tablePreferences?.RfqSaiQuotes?.gAndA || 100,
    },
    {
      name: 'saiSAndH',
      header: 'Y S&H',
      minWidth: 100,
      editable: true,
      render: ({ value, data }) => (
        <span
          className={
            data.status ? 'text-green-500' : 'text-red-500 line-through'
          }
        >{`$${value}`}</span>
      ),
      defaultWidth: tablePreferences?.RfqSaiQuotes?.saiSAndH || 100,
    },
    {
      name: 'saiTax',
      header: 'Y Tax',
      minWidth: 100,
      editable: true,
      render: ({ value, data }) => (
        <span
          className={
            data.status ? 'text-green-500' : 'text-red-500 line-through'
          }
        >{`${value}%`}</span>
      ),
      defaultWidth: tablePreferences?.RfqSaiQuotes?.saiTax || 100,
    },
    {
      name: 'saiTotal',
      header: 'Y Total',
      minWidth: 100,
      render: ({ value, data }) => {
        const formattedValue = value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return (
          <span
            className={
              data.status ? 'text-green-500' : 'text-red-500 line-through'
            }
          >{`$${formattedValue}`}</span>
        );
      },
      defaultWidth: tablePreferences?.RfqSaiQuotes?.saiTotal || 100,
    },
    {
      name: 'vendorName',
      header: 'Vendor Name',
      minWidth: 250,
      render: ({ value, data }) => (
        <a
          href={`mailto:${data.vendorEmail}`}
          className={data.status ? 'underline text-blue-500' : 'line-through'}
        >
          {value}
        </a>
      ),
      defaultWidth: tablePreferences?.RfqSaiQuotes?.vendorName || 250,
    },
    {
      name: 'comment',
      header: 'Comments',
      minWidth: 200,
      defaultWidth: tablePreferences?.RfqSaiQuotes?.comment || 200,
    },
  ];

  const orderedSaiQuotesColumns = orderColumns(
    saiQuotesColumns,
    tablePreferences?.RfqSaiQuotes?.order
  );

  const saiQuotesFilterValue = [
    { name: 'clin', operator: 'contains', type: 'string', value: '' },
    { name: 'name', operator: 'contains', type: 'string', value: '' },
    {
      name: 'deliveryAddress',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    { name: 'qty', operator: 'gte', type: 'number', value: '' },
    { name: 'unit', operator: 'contains', type: 'string', value: '' },
    { name: 'vQty', operator: 'gte', type: 'number', value: '' },
    { name: 'price', operator: 'gte', type: 'number', value: '' },
    { name: 'sAndH', operator: 'gte', type: 'number', value: '' },
    { name: 'tax', operator: 'gte', type: 'number', value: '' },
    { name: 'total', operator: 'gte', type: 'number', value: '' },
    { name: 'saiQty', operator: 'gte', type: 'number', value: '' },
    { name: 'margin', operator: 'gte', type: 'number', value: '' },
    { name: 'gAndA', operator: 'gte', type: 'number', value: '' },
    { name: 'saiSAndH', operator: 'gte', type: 'number', value: '' },
    { name: 'saiTax', operator: 'gte', type: 'number', value: '' },
    { name: 'saiTotal', operator: 'gte', type: 'number', value: '' },
    { name: 'vendorName', operator: 'contains', type: 'string', value: '' },
  ];

  const sortedViewBids = viewBids.sort((a, b) => b.status - a.status);
  // // console.log(sortedViewBids)
  const viewSaiQuotesGridData = useCallback(() => {
    const formattedBids = sortedViewBids.map((viewBid) => {
      const saiQty = parseFloat(viewBid.saiQty || 0);
      const price = parseFloat(viewBid.price || 0);
      const margin = parseFloat(viewBid.margin || 0);
      const saiTax = parseFloat(viewBid.saiTax || 0);
      const saiSAndH = parseFloat(viewBid.saiSAndH || 0);
      const gAndA = parseFloat(viewBid.gAndA || 0);
      const sAndH = parseFloat(viewBid.sAndH || 0);
      const tax = parseFloat(viewBid.tax || 0);
      const vQty = parseFloat(viewBid.vQty || 0);

      const saiTotal =
        saiQty === 0
          ? 0
          : (
              saiQty * price * (1 + margin / 100) * (1 + saiTax / 100) +
              saiSAndH +
              gAndA +
              sAndH +
              saiQty * price * (tax / 100)
            ).toFixed(2);

      return {
        uniqueId: viewBid.uniqueId,
        clin: viewBid.clin,
        name: viewBid.itemName,
        qty: viewBid.qty,
        unit: viewBid.unit,
        description: viewBid.description,
        deliveryAddress: viewBid.deliveryAddress,
        vendorName: viewBid.vendorName,
        vendorEmail: viewBid.primaryEmail,
        status: viewBid.status,
        comment: viewBid.comment,
        vendorCost: (
          parseFloat(viewBid.saiQty || 0) *
            parseFloat(viewBid.price || 0) *
            (1 + (parseInt(viewBid.tax) || 0) / 100) +
          parseFloat(viewBid.sAndH || 0)
        ).toFixed(2),
        vQty: parseFloat(viewBid.vQty || 0).toFixed(2),
        price: parseFloat(viewBid.price || 0).toFixed(2),
        sAndH: parseFloat(viewBid.sAndH || 0).toFixed(2),
        tax: parseFloat(viewBid.tax || 0).toFixed(2),
        total: parseFloat(
          (parseFloat(viewBid.vQty) || 0) *
            (parseFloat(viewBid.price) || 0) *
            (1 + (parseFloat(viewBid.tax) || 0) / 100) +
            (parseFloat(viewBid.sAndH) || 0)
        ).toFixed(2),
        saiQty: parseFloat(viewBid.saiQty || 0).toFixed(2),
        margin: parseFloat(viewBid.margin || 0).toFixed(2),
        gAndA: parseFloat(viewBid.gAndA || 0).toFixed(2),
        saiSAndH: parseFloat(viewBid.saiSAndH || 0).toFixed(2),
        saiTax: parseFloat(viewBid.saiTax || 0).toFixed(2),
        saiTotal: saiTotal,
      };
    });
    // // console.log(formattedBids);
    setSaiQuotesData(formattedBids);
    return formattedBids;
  }, [sortedViewBids]);

  useEffect(() => {
    viewSaiQuotesGridData();
  }, [viewSaiQuotesGridData]);

  const onEditCompleteViewSaiQuotesGrid = (newData) => {
    // console.log('newData', newData);
    var indexNo = -1;
    viewBids.forEach((item, index) => {
      if (item.uniqueId === newData.rowId) {
        indexNo = index;
      }
    });
    const columnId = newData.columnId;
    viewBids[indexNo][columnId] = newData.value;
    // console.log(viewBids[indexNo]['gAndA']);

    const saiQty = viewBids[indexNo]['saiQty'] || 0;
    const price = viewBids[indexNo]['price'] || 0;
    const prodsaiQtyAndprice = Number(saiQty) * Number(price) || 0;
    const margin = viewBids[indexNo]['margin'] || 0;
    const prodMargin = (Number(prodsaiQtyAndprice) * Number(margin)) / 100 || 0;
    const ga = Number(viewBids[indexNo]['gAndA']) || 0;
    const saiSAndH = Number(viewBids[indexNo]['saiSAndH']) || 0;
    const saiTax = Number(viewBids[indexNo]['saiTax']) || 0;

    const taxAmount = prodsaiQtyAndprice * (saiTax / 100) || 0;

    // console.log('prodsaiQtyAndprice', prodsaiQtyAndprice);
    // console.log('prodMargin', prodMargin);
    // console.log('ga', ga);
    // console.log('saiSAndH', saiSAndH);
    // console.log('taxAmount', taxAmount);

    const saiTotal =
      prodsaiQtyAndprice + prodMargin + ga + saiSAndH + taxAmount || 0;

    // console.log(saiTotal);
    viewBids[indexNo]['saiTotal'] = saiTotal;

    // console.log(viewBids);
    updateRFQArray('quotes', viewBids, docId);
  };

  const rfqPacketsColumns = [
    {
      name: 'clin',
      header: 'CLIN',
      minWidth: 150,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
      defaultWidth: tablePreferences?.RfqPackets?.clin || 150,
    },
    {
      name: 'name',
      header: 'Item Name',
      minWidth: 250,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
      defaultWidth: tablePreferences?.RfqPackets?.name || 250,
    },
    {
      name: 'deliveryAddress',
      header: 'Delivery Address',
      minWidth: 250,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
      defaultWidth: tablePreferences?.RfqPackets?.deliveryAddress || 250,
    },
    {
      name: 'qty',
      header: 'Required Qty',
      minWidth: 100,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
      defaultWidth: tablePreferences?.RfqPackets?.qty || 100,
    },
    {
      name: 'unit',
      header: 'Unit',
      minWidth: 100,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
      defaultWidth: tablePreferences?.RfqPackets?.unit || 100,
    },
    {
      name: 'vendorCost',
      header: 'Vendor Cost',
      minWidth: 100,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>${value}</span>
      ),
      defaultWidth: tablePreferences?.RfqPackets?.vendorCost || 100,
    },
    {
      name: 'saiQty',
      header: 'Y Qty',
      minWidth: 100,
      render: ({ value, data }) => (
        <span
          className={
            data.status ? 'text-green-500' : 'text-red-500 line-through'
          }
        >{`${value}`}</span>
      ),
      defaultWidth: tablePreferences?.RfqPackets?.saiQty || 100,
    },
    {
      name: 'margin',
      header: 'Margin',
      minWidth: 100,
      render: ({ value, data }) => (
        <span
          className={
            data.status ? 'text-green-500' : 'text-red-500 line-through'
          }
        >{`${value}%`}</span>
      ),
      defaultWidth: tablePreferences?.RfqPackets?.margin || 100,
    },
    {
      name: 'gAndA',
      header: 'G&A',
      minWidth: 100,
      render: ({ value, data }) => (
        <span
          className={
            data.status ? 'text-green-500' : 'text-red-500 line-through'
          }
        >{`$${value}`}</span>
      ),
      defaultWidth: tablePreferences?.RfqPackets?.gAndA || 100,
    },
    {
      name: 'saiSAndH',
      header: 'Y S&H',
      minWidth: 100,
      render: ({ value, data }) => (
        <span
          className={
            data.status ? 'text-green-500' : 'text-red-500 line-through'
          }
        >{`$${value}`}</span>
      ),
      defaultWidth: tablePreferences?.RfqPackets?.saiSAndH || 100,
    },
    {
      name: 'saiTax',
      header: 'Y Tax',
      minWidth: 100,
      render: ({ value, data }) => (
        <span
          className={
            data.status ? 'text-green-500' : 'text-red-500 line-through'
          }
        >{`${value}%`}</span>
      ),
      defaultWidth: tablePreferences?.RfqPackets?.saiTax || 100,
    },
    {
      name: 'saiTotal',
      header: 'Y Total',
      minWidth: 100,
      render: ({ value, data }) => {
        const formattedValue = value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return (
          <span
            className={
              data.status ? 'text-green-500' : 'text-red-500 line-through'
            }
          >{`$${formattedValue}`}</span>
        );
      },
      defaultWidth: tablePreferences?.RfqPackets?.saiTotal || 100,
    },
    {
      name: 'comment',
      header: 'Comments',
      minWidth: 200,
      defaultWidth: tablePreferences?.RfqPackets?.comment || 200,
    },
  ];

  const orderedRfqPacketsColumns = orderColumns(
    rfqPacketsColumns,
    tablePreferences?.RfqPackets?.order
  );

  const rfqPacketsFilterValue = [
    { name: 'clin', operator: 'contains', type: 'string', value: '' },
    { name: 'name', operator: 'contains', type: 'string', value: '' },
    {
      name: 'deliveryAddress',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    { name: 'qty', operator: 'gte', type: 'number', value: '' },
    { name: 'unit', operator: 'contains', type: 'string', value: '' },
    { name: 'vendorCost', operator: 'gte', type: 'number', value: '' },
    { name: 'vQty', operator: 'gte', type: 'number', value: '' },
    { name: 'price', operator: 'gte', type: 'number', value: '' },
    { name: 'sAndH', operator: 'gte', type: 'number', value: '' },
    { name: 'tax', operator: 'gte', type: 'number', value: '' },
    { name: 'total', operator: 'gte', type: 'number', value: '' },
    { name: 'saiQty', operator: 'gte', type: 'number', value: '' },
    { name: 'margin', operator: 'gte', type: 'number', value: '' },
    { name: 'gAndA', operator: 'gte', type: 'number', value: '' },
    { name: 'saiSAndH', operator: 'gte', type: 'number', value: '' },
    { name: 'saiTax', operator: 'gte', type: 'number', value: '' },
    { name: 'saiTotal', operator: 'gte', type: 'number', value: '' },
    { name: 'vendorName', operator: 'contains', type: 'string', value: '' },
  ];

  const rfqPacketsGridData = useCallback(() => {
    const filteredBids = viewBids
      .filter((viewBid) => viewBid.status)
      .map((viewBid) => {
        const saiQty = parseFloat(viewBid.saiQty || 0);
        const price = parseFloat(viewBid.price || 0);
        const margin = parseFloat(viewBid.margin || 0);
        const saiTax = parseFloat(viewBid.saiTax || 0);
        const saiSAndH = parseFloat(viewBid.saiSAndH || 0);
        const gAndA = parseFloat(viewBid.gAndA || 0);
        const sAndH = parseFloat(viewBid.sAndH || 0);
        const tax = parseFloat(viewBid.tax || 0);
        const vQty = parseFloat(viewBid.vQty || 0);

        const saiTotal =
          saiQty === 0
            ? 0
            : (
                saiQty * price * (1 + margin / 100) * (1 + saiTax / 100) +
                saiSAndH +
                gAndA +
                sAndH +
                saiQty * price * (tax / 100)
              ).toFixed(2);

        return {
          ...viewBid,
          vendorCost: (
            parseFloat(viewBid.saiQty || 0) *
              parseFloat(viewBid.price || 0) *
              (1 + (parseInt(viewBid.tax) || 0) / 100) +
            parseFloat(viewBid.sAndH || 0)
          ).toFixed(2),
          saiTotal: saiTotal,
        };
      });

    // // console.log(filteredBids);

    // Group bids by clin
    const bidsByClin = filteredBids.reduce((groups, bid) => {
      const key = bid.clin;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(bid);
      return groups;
    }, {});

    // Calculate sum and max for each clin
    const result = Object.entries(bidsByClin).map(([clin, bids]) => {
      const maxReqdQty = bids.reduce(
        (max, bid) => Math.max(max, Number(bid.qty || 0)),
        0
      );
      const sumSaiQty = bids.reduce(
        (sum, bid) => Number(sum) + Number(bid.saiQty || 0),
        0
      );
      const sumMargin = bids.reduce(
        (sum, bid) => Number(sum) + Number(bid.margin || 0),
        0
      );
      const sumGAndA = bids.reduce(
        (sum, bid) => Number(sum) + Number(bid.gAndA || 0),
        0
      );
      const sumSaiSAndH = bids.reduce(
        (sum, bid) => Number(sum) + Number(bid.saiSAndH || 0),
        0
      );
      const sumVendorCost = bids.reduce(
        (sum, bid) => Number(sum) + Number(bid.vendorCost || 0),
        0
      );
      const sumTotal = bids.reduce(
        (sum, bid) => Number(sum) + Number(bid.saiTotal || 0),
        0
      );
      const maxSaiTax = Math.max(...bids.map((bid) => bid.saiTax || 0));

      const clins = [];
      bids = bids.filter((item) => {
        if (!clins.includes(item.clin)) {
          clins.push(item.clin);
          return true; // Keep the item in the array
        }
        return false; // Exclude the item from the array
      });

      bids.forEach((bid) => {
        const prodsaiQtyAndprice = sumSaiQty * Number(bid.price);
        const prodMargin =
          (Number(prodsaiQtyAndprice) * Number(sumMargin)) / 100;
        const taxAmount =
          Number(prodsaiQtyAndprice) * (Number(maxSaiTax) / 100);
        const saiTotal =
          prodsaiQtyAndprice + prodMargin + sumGAndA + sumSaiSAndH + taxAmount;
        bid['total'] = saiTotal || 0;
      });

      return bids.map((viewBid) => ({
        uniqueId: viewBid.uniqueId,
        clin: viewBid.clin,
        name: viewBid.itemName,
        comment: viewBid.comment,
        vendorCost: sumVendorCost,
        qty: maxReqdQty,
        unit: viewBid.unit,
        deliveryAddress: viewBid.deliveryAddress,
        status: viewBid.status,
        saiQty: sumSaiQty,
        margin: sumMargin,
        gAndA: sumGAndA,
        saiSAndH: sumSaiSAndH,
        saiTax: maxSaiTax,
        saiTotal: sumTotal.toFixed(2),
      }));
    });

    // Flatten the array
    return result.flat();
  }, [viewBids]);

  const combinedClinData = rfqPacketsGridData();

  useEffect(() => {
    setFinalMail(tempMailContent);

    // Use a local variable instead of the state for the temporary mail content.
  }, [tempMailContent]);

  useEffect(() => {
    setfinalRQMailContent(tempRQMailContent);

    // Use a local variable instead of the state for the temporary mail content.
  }, [tempRQMailContent]);

  const onEditCompleteItemsGrid = (newData) => {
    // console.log(newData);
    setItems((prevItems) => {
      // Find the index of the item with the same id as the new item
      const itemIndex = prevItems.findIndex(
        (item) => item.itemId === newData.rowId
      );

      // Replace the old item with the new item
      const newItems = [...prevItems];
      newItems[itemIndex] = {
        ...newItems[itemIndex],
        [newData.columnId]: newData.value,
      };

      // Save the changes and filter out items where clin is an empty string
      updateRFQArray(
        'items',
        newItems.filter((item) => item.clin !== ''),
        docId
      );

      return newItems;
    });
  };

  const onEditCompleteAttachmentsGrid = (newData) => {
    setAttachments((prevAttachments) => {
      const attachmentIndex = prevAttachments.findIndex(
        (attachment) => attachment.attachmentId === newData.rowId
      );

      const newAttachments = [...prevAttachments];
      newAttachments[attachmentIndex] = {
        ...newAttachments[attachmentIndex],
        [newData.columnId]: newData.value,
      };

      updateRFQArray('attachments', newAttachments, docId);

      return newAttachments;
    });
  };

  const confirmRequestQuotesFunction = () => {
    setIsConfirmModalOpen(false);
    setIsSendRequestQuoteEmailModalOpen(true);
  };

  const sendRequestQuotesEmails = async () => {
    setIsLoading(true);

    // Prepare vendor data
    const locals = selectedVendorsArray.map((vendor) => ({
      primaryPSCCategory: vendor.primaryPSCCategory,
      streetAddress: vendor.streetAddress,
      poBox: vendor.poBox,
      primaryContactName: vendor.primaryContactName,
      updatedAt: vendor.updatedAt,
      rating: vendor.rating,
      vendorId: vendor.vendorId,
      zipCode: vendor.zipCode,
      streetAddress2: vendor.streetAddress2,
      primaryEmail: vendor.primaryEmail,
      secondaryPhone: vendor.secondaryPhone,
      comments: vendor.comments,
      description: vendor.description,
      sendResellerCertificate: vendor.sendResellerCertificate,
      primaryPhone: vendor.primaryPhone,
      city: vendor.city,
      state: vendor.state,
      secondaryEmail: vendor.secondaryEmail,
      vendorName: vendor.vendorName,
      secondaryContactName: vendor.secondaryContactName,
      secondaryPSCCategory: vendor.secondaryPSCCategory,
      uniqueId: vendor.uniqueId,
      docId,
    }));

    // Filter and process selected attachments
    const selectedAttachments = attachmentRows?.filter(
      (row) => selectedRFQ.includes(row.name) // Change from row.id to row.name
    );

    // Prepare email data for each vendor
    const mails = await Promise.all(
      locals.map(async (local) => {
        let processedContent = replaceVariablesRQFinal(
          finalRQMailContent,
          local
        );

        processedContent += `<p style="font-size:1px; color: transparent;">Quote#ID#{{docId}}#QuoteIDEnd</p>`;
        processedContent += `<p style="font-size:1px; color: transparent;">#RFQ#QUOTE#RESPONSE#ID</p>`;

        processedContent = processedContent.replace('{{docId}}', local.docId);
        processedContent = processedContent.replace(/undefined/g, '');

        const processedMailSubject = replaceVariablesRQFinal(
          mailRQSubject,
          local
        );
        return {
          mailAddress: local.primaryEmail,
          mailBody: processedContent,
          mailSubject: processedMailSubject,
          mailAttachments: selectedAttachments,
        };
      })
    );

    // Send request to backend
    try {
      const response = await fetch(
        process.env.REACT_APP_URL_SEND_EMAIL_RFQ_QUOTE
,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(mails),
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      successAlert('Emails sent successfully');
      setIsSendRequestQuoteEmailModalOpen(false);
      setIsLoading(false);
      setSelectedRFQ([]);
      autoAddBid(docId, selectedVendorsArray, selectedItemsArray);
    } catch (error) {
      setIsLoading(false);
      errorAlert('Failed to send request quotes via email!');
    }
  };

  // const sendRequestQuotesEmails = async () => {
  //   setIsLoading(true); // Start loading
  //   const locals = selectedVendorsArray.map((vendor) => {
  //     return {
  //       primaryPSCCategory: vendor.primaryPSCCategory,
  //       streetAddress: vendor.streetAddress,
  //       poBox: vendor.poBox,
  //       primaryContactName: vendor.primaryContactName,
  //       updatedAt: vendor.updatedAt,
  //       rating: vendor.rating,
  //       vendorId: vendor.vendorId,
  //       zipCode: vendor.zipCode,
  //       streetAddress2: vendor.streetAddress2,
  //       primaryEmail: vendor.primaryEmail,
  //       secondaryPhone: vendor.secondaryPhone,
  //       comments: vendor.comments,
  //       description: vendor.description,
  //       sendResellerCertificate: vendor.sendResellerCertificate,
  //       primaryPhone: vendor.primaryPhone,
  //       city: vendor.city,
  //       state: vendor.state,
  //       secondaryEmail: vendor.secondaryEmail,
  //       vendorName: vendor.vendorName,
  //       secondaryContactName: vendor.secondaryContactName,
  //       secondaryPSCCategory: vendor.secondaryPSCCategory,
  //       uniqueId: vendor.uniqueId,
  //       docId,
  //     };
  //   });

  //   const processedMails = locals.map((local) => {
  //     let processedContent = replaceVariablesRQFinal(finalRQMailContent, local);

  //     processedContent += `<p style="font-size:1px; color: transparent;">Quote#ID#{{docId}}#QuoteIDEnd</p>`;
  //     processedContent += `<p style="font-size:1px; color: transparent;">#RFQ#QUOTE#RESPONSE#ID</p>`;

  //     processedContent = processedContent.replace('{{docId}}', local.docId);
  //     processedContent = processedContent.replace(/undefined/g, '');

  //     const processedMailSubject = replaceVariablesRQFinal(
  //       mailRQSubject,
  //       local
  //     );
  //     const email = local.primaryEmail;
  //     return {
  //       mailAddress: email,
  //       mailBody: processedContent,
  //       mailSubject: processedMailSubject,
  //     };
  //   });
  //   // console.log(processedMails);

  //   // const formData = {
  //   //   vendors: selectedVendorsArray,
  //   //   items: selectedItemsArray,
  //   //   rfqId: docId,
  //   // };

  //   processedMails.forEach(async (mail) => {
  //     try {
  //       const response = await fetch(
  //         // `${process.env.REACT_APP_URL}send-email/rfq-quote`,
  //         `${process.env.REACT_APP_URL}/send-email/rfq-quote`,
  //         {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify(mail),
  //         }
  //       );

  //       if (!response.ok) {
  //         setIsLoading(false);
  //         throw new Error('Network response was not ok');
  //       }

  //       await response.json();
  //       successAlert('Emails sent successfully');
  //       setIsSendRequestQuoteEmailModalOpen(false);
  //       setIsLoading(false);
  //       autoAddBid(docId, selectedVendorsArray, selectedItemsArray);
  //     } catch (error) {
  //       setIsLoading(false);
  //       errorAlert('Failed to send request quotes via email!');
  //     }
  //   });
  // };

  function isValidEmail(email) {
    // Use a regular expression to check for a valid email pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const sendRFQPacketEmail = async () => {
    const validMailAddresses = emails.filter(isValidEmail);
    const selectedAttachments = attachmentRows?.filter((row) =>
      selectedRFQ.includes(row.name)
    );

    // console.log('validMailAddresses', validMailAddresses);

    const formData = {
      mailBody: finalMail,
      mailSubject,
      mailAddresses: validMailAddresses,
      mailAttachements: selectedAttachments,
    };
    // console.log(formData);
    try {
      setIsLoading(true); // Start loading
      const response = await fetch(
        //`${process.env.REACT_APP_URL}send-email/rfq-packet`,
        process.env.REACT_APP_URL_SEND_EMAIL_RFQ_PACKET
,
        // 'http://localhost:3001/send-email/rfq-packet',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );
      if (!response.ok) {
        setIsLoading(false);
        throw new Error('Network response was not ok');
      }

      await response.json();
      successAlert('Emails sent successfully');
      setIsSendRequestQuoteEmailModalOpen(false);
      setIsLoading(false);
      autoAddBid(docId, selectedVendorsArray, selectedItemsArray);
      updateRFQ(docId, { packetStatus: 'Sent' });
      setSelectedRFQ([]);
    } catch (error) {
      setIsLoading(false);
      errorAlert('Failed to send request quotes via email!');
    }
  };

  const selectedItemsArray = Object.values(selectedItems);
  const selectedVendorsArray = Object.values(selectedVendors);

  console.log(selectedVendorsArray, 'selectedVendorsArray');

  const autoAddBid = async (rfqId, vendors, items) => {
    let combinedArray = [];

    items.forEach((item) => {
      vendors.forEach((vendor) => {
        combinedArray.push({
          ...item,
          ...vendor,
          uniqueId: uuidv4(),
          status: true,
        });
      });
    });

    // Combine the new array with the existing viewBids array
    const updatedBidsArray = viewBids.concat(combinedArray);

    updatedBidsArray.forEach((item, index) => {
      for (let key in item) {
        if (item[key] === undefined) {
          updatedBidsArray[index][key] = '';
        }
      }
    });

    try {
      await updateRFQArray('quotes', updatedBidsArray, rfqId);
      // console.log('Quotes updated successfully.');
    } catch (error) {
      console.error('Failed to update bids:', error);
    }
  };

  const manualAddBid = () => {
    const selectedItem = items.find(
      (item) => item.itemId === selectedNewBidItemId
    );
    const selectedVendor = vendorsData.find(
      (vendor) => vendor.vendorId === selectedNewBidVendorId
    );

    const newBid = {
      vQty: vendorAddQty,
      price: vendorAddPrice,
      sAndH: vendorAddSAndH,
      tax: vendorAddTax,
      total: vendorAddTotal,
      category: selectedItem.category,
      state: selectedVendor.state,
      status: true,
      city: selectedVendor.city,
      vendorName: selectedVendor.vendorName,
      description: selectedItem.description,
      deliveryAddress: selectedItem.deliveryAddress,
      clin: selectedItem.clin,
      qty: selectedItem.qty,
      unit: selectedItem.unit,
      primaryPSCCategory: selectedVendor.primaryPSCCategory,
      primaryEmail: selectedVendor.primaryEmail,
      itemName: selectedItem.itemName,
      comment: selectedItem.comment,
      uniqueId: uuidv4(),
    };

    // console.log('newBid', newBid);

    setViewBids((prevBids) => {
      const updatedBids = [...prevBids, newBid];

      updateRFQArray('quotes', updatedBids, docId);

      return updatedBids;
    });

    setIsAddNewBidModalOpen(false);

    setSelectedNewBidItemId(null);
    setSelectedNewBidVendorId(null);
    setVendorAddQty(0);
    setVendorAddPrice(0);
    setVendorAddSAndH(0);
    setVendorAddTax(0);
    setVendorAddTotal(0);
  };

  const sendRFQPacketForReview = async () => {
    setIsLoading(true);
    const formData = {
      review: true,
      sendForReviewTime: new Date().getTime(),
    };
    try {
      await updateRFQ(docId, formData);
      setIsSendForReviewModalOpen(false);
    } catch (error) {
      console.error('Error sending RFQ packet for review:', error);
    } finally {
      setIsLoading(false);
    }
  };  
  
  const handleFileUpload = async (files) => {
    setIsUploading(true);
    let uploadedFiles = [];
    
    for (const file of files) {
      const fileId = uuidv4();
      const fileMetadata = { contentType: file.type };
      
      try {
        const downloadURL = await storeFilePackets(file, fileId, fileMetadata);
        uploadedFiles.push({ name: file.name, attachmentUrl: downloadURL });
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
    
    const newAttachments = [...(packetsAttachments || []), ...uploadedFiles];
    setPacketsAttachments(newAttachments);
    updateRFQArray('packetsAttachments', newAttachments, docId);
    setIsUploading(false);
  };

  const handleDeleteAttachment = (index) => {
    setPacketsAttachments((currentAttachments) => {
      const updatedAttachments = [...currentAttachments];
      updatedAttachments.splice(index, 1);
      return updatedAttachments;
    });
  };

  const handleFileChange = (event) => {
    handleFileUpload(event.target.files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFileUpload(files);
  };



  const handleAttachmentDelete = (index) => {
    const tempAttachments = [...attachments];
    tempAttachments.splice(index, 1);
    setAttachments(tempAttachments);
  };

  const handleAttachmentChange = (e) => {
    const selectedFile = attachments.find(
      (file) => file.fileName === e.target.value || file.name === e.target.value
    );
    if (selectedFile) {
      setAttachmentRows([
        ...attachmentRows,
        {
          name: selectedFile.fileName || selectedFile.name,
          attachmentUrl: selectedFile.attachmentUrl,
        },
      ]);
    }
  };

  const handleDeleteAttachmentRow = (index) => {
    const newAttachmentRows = [...attachmentRows];
    newAttachmentRows.splice(index, 1);
    setAttachmentRows(newAttachmentRows);
  };

  const replaceVariablesRQFinal = (template, values) => {
    const variableRegex = /\{\{([^{}]+)\}\}/g;

    let replacedTemplate = template.replace(
      variableRegex,
      (match, variable) => {
        return values[variable] || '';
      }
    );
    return replacedTemplate;
  };

  // const replaceVariablesRQTemplate = (template, values) => {
  //   const variableRegex = /\{\{([^{}]+)\}\}/g;
  //   const tableRegex = /<table[^>]*>[\s\S]*?<\/table>/i;

  //   const hasTable = tableRegex.test(template);

  //   if (hasTable) {
  //     const tableContent = template.match(tableRegex)[0];
  //     const tableRows = tableContent.match(/<tr[^>]*>[\s\S]*?<\/tr>/gi);

  //     if (tableRows && tableRows.length >= 2) {
  //       const secondRow = tableRows[1];

  //       // Replace variables in the existing rows
  //       const replacedRows = values['items']
  //         .map((item) => {
  //           return secondRow.replace(variableRegex, (match, variable) => {
  //             return item[variable] || ''; // Handle cases where variable might not exist
  //           });
  //         })
  //         .join('');

  //       // Create a new row for Quote IDs within the table
  //       const quoteIdRow = `<tr style="border-top: 2px solid black;">
  //             <td colspan="${
  //               tableRows[0].match(/<th[^>]*>|<td[^>]*>/g).length
  //             }" style="text-align: start; border: 1px solid black;">
  //                 Quote ID: ${values['items']
  //                   .map((item) => item.itemId)
  //                   .join(', ')}
  //             </td>
  //         </tr>`;

  //       // Insert replaced rows and the new Quote ID row within the table
  //       const modifiedTable = tableContent
  //         .replace(
  //           tableRows[1], // Replace only the second row
  //           replacedRows
  //         )
  //         .replace(/<\/table>/, `${quoteIdRow}</table>`); // Add Quote ID row before closing </table>

  //       template = template.replace(tableRegex, modifiedTable);
  //     }
  //   }

  //   // Replace each variable with its corresponding value
  //   let variableToReplace = '{{docId}}';
  //   let replacementValue = values['docId'] || '';

  //   let replacedTemplate = template.replace(
  //     variableToReplace,
  //     replacementValue
  //   );

  //   return replacedTemplate;
  // };

  const replaceVariablesRQTemplate = (template, values) => {
    // Regular expression to match variables in double curly braces
    const variableRegex = /\{\{([^{}]+)\}\}/g;

    // Regular expression to match a table with its content
    const tableRegex = /<table[^>]*>[\s\S]*?<\/table>/i;

    // Check if the template contains a table
    const hasTable = tableRegex.test(template);

    if (hasTable) {
      // Extract the table content
      const tableContent = template.match(tableRegex)[0];

      // Find all table rows in the table content
      const tableRows = tableContent.match(/<tr[^>]*>[\s\S]*?<\/tr>/gi);

      if (tableRows && tableRows.length >= 2) {
        // Get the second table row
        const secondRow = tableRows[1];

        // Iterate through items array and replace variables in the second row
        const replacedSecondRow = values['items']
          .map((item) => {
            // Replace variables in the second row with item values
            // console.log(item);
            return secondRow.replace(variableRegex, (match, variable) => {
              // // console.log(variable);
              // // console.log(item[variable]);
              return item[variable];
            });
          })
          .join('');

        // // console.log(replacedSecondRow);

        // Replace the original second row in the table with the modified one
        const modifiedTable = tableContent.replace(
          secondRow,
          replacedSecondRow
        );

        // Replace the original table in the template with the modified one
        template = template.replace(tableRegex, modifiedTable);
      }
    }

    // Replace each variable with its corresponding value
    let variableToReplace = '{{docId}}';
    let replacementValue = values['docId'] || '';

    let replacedTemplate = template.replace(
      variableToReplace,
      replacementValue
    );
    // replacedTemplate =
    //   replacedTemplate +
    //   `
    //   <br/>
    //   <b>In reply to:</b>
    //   <div style="margin-left: 30px;">${cleanRfqEmail}</div>`;
    return replacedTemplate;
  };

  const replaceVariables = (template, values) => {
    // Regular expression to match variables in double curly braces
    const variableRegex = /\{\{([^{}]+)\}\}/g;

    // Regular expression to match a table with its content
    const tableRegex = /<table[^>]*>[\s\S]*?<\/table>/i;

    // Check if the template contains a table
    const hasTable = tableRegex.test(template);

    if (hasTable) {
      // Extract the table content
      const tableContent = template.match(tableRegex)[0];

      // Find all table rows in the table content
      const tableRows = tableContent.match(/<tr[^>]*>[\s\S]*?<\/tr>/gi);

      if (tableRows && tableRows.length >= 2) {
        // Get the second table row
        const secondRow = tableRows[1];

        // Iterate through items array and replace variables in the second row
        const replacedSecondRow = values['items']
          .map((item) => {
            // Replace variables in the second row with item values
            // console.log(item);
            return secondRow.replace(variableRegex, (match, variable) => {
              // console.log(variable);
              // console.log(item[variable]);
              return item[variable];
            });
          })
          .join('');

        // console.log(replacedSecondRow);

        // Replace the original second row in the table with the modified one
        const modifiedTable = tableContent.replace(
          secondRow,
          replacedSecondRow
        );

        // Replace the original table in the template with the modified one
        template = template.replace(tableRegex, modifiedTable);
      }
    }

    // Replace each variable with its corresponding value
    let replacedTemplate = template.replace(
      variableRegex,
      (match, variable) => {
        // Use values[variable] or an empty string if the variable is not found
        return values[variable] || '';
      }
    );
    replacedTemplate =
      replacedTemplate +
      `
      <br/>
      <b>In reply to:</b>
      <div style="margin-left: 30px;">${cleanRfqEmail}</div>`;
    return replacedTemplate;
  };

  const replaceSubjectVariables = (subject, values) => {
    const variableRegex = /\{\{([^{}]+)\}\}/g;

    return subject.replace(variableRegex, (match, variable) => {
      return values[variable] || '';
    });
  };

  const handleRQTemplateSelection = (emailTempId) => {
    const selectedTemplate = emailRQTemplates.filter(
      (template) => template.emailTempId === emailTempId
    );
    const selectedVendorsEmails = selectedVendorsArray.map(
      (vendor) => vendor.primaryEmail
    );
    setEmailsRQ(selectedVendorsEmails);
    // console.log(selectedVendorsArray);

    const local = {
      items: selectedItemsArray.map((item) => {
        return {
          clin: item.clin,
          name: item.itemName,
          deliveryAddress: item.deliveryAddress,
          description: item.description,
          qty: item.qty,
          dueBy: item.dueBy,
          itemId: item.itemId,
          unit: item.unit,
          comment: item.comment,
          category: item.category,
        };
      }),
      docId,
    };

    // // console.log(local);

    const rawContent = selectedTemplate[0]?.template;
    const rawSubject = selectedTemplate[0]?.mailSubject;
    if (rawContent) {
      const processedContent = replaceVariablesRQTemplate(rawContent, local);
      // // console.log(processedContent);
      setTempRQMailContent(processedContent);
    }
    setMailRQSubject(rawSubject);
    // // console.log(rawContent);
  };

  const handleTemplateSelection = (emailTempId) => {
    const selectedTemplate = emailTemplates.filter(
      (template) => template.emailTempId === emailTempId
    );

    let totalAmount = combinedClinData
      .reduce((total, item) => {
        return total + parseFloat(item.saiTotal);
      }, 0)
      .toFixed(2);

    const local = {
      primaryContactName,
      agency,
      items: combinedClinData.map((item) => {
        // console.log(item);
        return {
          clin: item.clin,
          name: item.name,
          deliveryAddress: item.deliveryAddress,
          qty: item.qty,
          gAndA: item.gAndA,
          saiSAndH: item.saiSAndH,
          margin: item.margin,
          unit: item.unit,
          saiQty: item.saiQty,
          saiTax: item.saiTax,
          saiTotal: item.saiTotal,
          comment: item.comment,
          vendorCost: item.vendorCost,
        };
      }),
      totalAmount,
      docId,
      dueDate,
      rfqNo,
    };

    // console.log(local);

    const rawContent = selectedTemplate[0]?.template;
    const rawSubject = selectedTemplate[0]?.mailSubject;
    if (rawContent) {
      const processedContent = replaceVariables(rawContent, local);
      // console.log(processedContent);
      setTempMailContent(processedContent);
    }
    if (rawSubject) {
      const processedMailSubject = replaceSubjectVariables(rawSubject, local);
      setMailSubject(processedMailSubject);
    }
    // console.log(rawContent);
  };

  const handleColumnResize = async (e, tableName) => {
    // console.log('tablePref: ', tablePreferences);
    const updatedColumnName = e.column.name;
    const updatedWidth = e.width;

    const preferences = {
      [updatedColumnName]: updatedWidth,
    };

    await updateTablePref(tableName, preferences);
  };

  const handleColumnOrderChange = async (e, tableName) => {
    await updateTablePref(tableName, { order: e });
  };

  // Toggle selection based on the attachment name
  const toggleSelection = (name) => {
    setSelectedRFQ(
      (prevSelected) =>
        prevSelected.includes(name)
          ? prevSelected.filter((item) => item !== name) // Unselect if already selected
          : [...prevSelected, name] // Select if not selected
    );
  };

  // Handle select all items by name
  const handleSelectAll = () => {
    if (selectedRFQ.length === attachmentRows.length) {
      setSelectedRFQ([]);
    } else {
      setSelectedRFQ(attachmentRows.map((row) => row.name)); // Select all by name
    }
  };

  // Default to select all if attachmentRows exist
  useEffect(() => {
    if (attachmentRows?.length > 0) {
      setSelectedRFQ(attachmentRows.map((row) => row.name)); // Select all by default using name
    }
  }, [attachmentRows]);

  return (
    <>
      {isLoading && <Loader />}

      <div
        id="addUserData-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          openEditModal
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {headerStatement}
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setOpenEditModal(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <div className="border-b border-gray-200 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                  {[
                    'RFQ',
                    'Items',
                    'Attachment',
                    'Request Quotes',
                    'View Bids',
                    'Y Quotes',
                    'RFQ Packets',
                  ].map((tab) => (
                    <li key={tab} className="flex-grow me-2">
                      <button
                        onClick={() => setCurrentTab(tab)}
                        className={`inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group w-full ${
                          currentTab === tab
                            ? 'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500'
                            : ''
                        }`}
                      >
                        {tab}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>

              {currentTab === 'RFQ' && (
                <>
                  <section className="bg-white dark:bg-gray-900">
                    <h5 className="flex justify-between items-center text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      RFQ Details
                    </h5>
                    <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                      <div>
                        <label
                          htmlFor="rfqNo"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          RFQ No <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="rfqNo"
                          id="rfqNo"
                          className={`${
                            isEditable
                              ? 'bg-white'
                              : 'bg-gray-50 cursor-not-allowed'
                          } border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                          placeholder="1223211"
                          required
                          onChange={(event) => setRfqNo(event.target.value)}
                          defaultValue={rfqNo}
                          disabled={!isEditable}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="agency"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Agency <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="agency"
                          id="agency"
                          className={`${
                            isEditable
                              ? 'bg-white'
                              : 'bg-gray-50 cursor-not-allowed'
                          } border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                          placeholder="Ministry of Defence"
                          required
                          onChange={(event) => setAgency(event.target.value)}
                          defaultValue={agency}
                          disabled={!isEditable}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="releaseDate"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Release Date <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="date"
                          name="releaseDate"
                          id="releaseDate"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          required
                          onChange={(event) =>
                            setReleaseDate(event.target.value)
                          }
                          value={releaseDate}
                          disabled
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="dueDate"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Due Date <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="date"
                          name="dueDate"
                          id="dueDate"
                          className={`${
                            isEditable
                              ? 'bg-white'
                              : 'bg-gray-50 cursor-not-allowed'
                          } border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                          required
                          onChange={(event) => setDueDate(event.target.value)}
                          value={dueDate}
                          disabled={!isEditable}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="address"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Address <span className="text-red-600">*</span>
                        </label>
                        <textarea
                          name="address"
                          id="address"
                          rows="2"
                          className={`${
                            isEditable
                              ? 'bg-white'
                              : 'bg-gray-50 cursor-not-allowed'
                          } border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                          required
                          onChange={(event) => setAddress(event.target.value)}
                          defaultValue={address}
                          placeholder="Add the Delivery Address"
                          disabled={!isEditable}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="primaryContact"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Primary Contact{' '}
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="primaryContact"
                          id="primaryContact"
                          className={`${
                            isEditable
                              ? 'bg-white'
                              : 'bg-gray-50 cursor-not-allowed'
                          } border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                          placeholder="John Doe"
                          required
                          onChange={(event) =>
                            setPrimaryContactName(event.target.value)
                          }
                          defaultValue={primaryContactName}
                          disabled={!isEditable}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="primary_phone"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Primary Phone <span className="text-red-600">*</span>
                        </label>
                        <div className="relative">
                          <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                            <svg
                              className="w-4 h-4 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 19 18"
                            >
                              <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                            </svg>
                          </div>
                          <input
                            type="text"
                            id="primary_phone"
                            className={`${
                              isEditable
                                ? 'bg-white'
                                : 'bg-gray-50 cursor-not-allowed'
                            } border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 ps-10`}
                            pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
                            placeholder="(123) 456-7890"
                            required
                            onChange={(event) =>
                              handlePhoneNumberChange(event, setPrimaryPhone)
                            }
                            defaultValue={primaryPhone}
                            disabled={!isEditable}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="primary_email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Primary Email <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="primary_email"
                          id="primary_email"
                          onChange={handlePrimaryEmailChange}
                          defaultValue={primaryEmail}
                          className={`${
                            isEditable
                              ? 'bg-white'
                              : 'bg-gray-50 cursor-not-allowed'
                          } border ${
                            isPrimaryEmailValid
                              ? 'border-gray-300'
                              : 'border-red-600'
                          } text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                          placeholder="user@domain.com"
                          required
                          disabled={!isEditable}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="secondaryContact"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Secondary Contact
                        </label>
                        <input
                          type="text"
                          name="secondaryContact"
                          id="secondaryContact"
                          className={`${
                            isEditable
                              ? 'bg-white'
                              : 'bg-gray-50 cursor-not-allowed'
                          } border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                          placeholder="Jane Doe"
                          onChange={(event) =>
                            setSecondaryContactName(event.target.value)
                          }
                          defaultValue={secondaryContactName}
                          disabled={!isEditable}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="secondary_phone"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Secondary Phone
                        </label>
                        <div className="relative">
                          <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                            <svg
                              className="w-4 h-4 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 19 18"
                            >
                              <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                            </svg>
                          </div>
                          <input
                            type="text"
                            id="secondary_phone"
                            className={`${
                              isEditable
                                ? 'bg-white'
                                : 'bg-gray-50 cursor-not-allowed'
                            } border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 ps-10`}
                            pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
                            placeholder="(123) 456-7890"
                            onChange={(event) =>
                              handlePhoneNumberChange(event, setSecondaryPhone)
                            }
                            defaultValue={secondaryPhone}
                            disabled={!isEditable}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="secondary_email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Secondary Email
                        </label>
                        <input
                          type="text"
                          name="secondary_email"
                          id="secondary_email"
                          onChange={handleSecondaryEmailChange}
                          defaultValue={secondaryEmail}
                          className={`${
                            isEditable
                              ? 'bg-white'
                              : 'bg-gray-50 cursor-not-allowed'
                          } border ${
                            isSecondaryEmailValid
                              ? 'border-gray-300'
                              : 'border-red-600'
                          } text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                          placeholder="user@domain.com"
                          disabled={!isEditable}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="comments"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Comments
                        </label>
                        <textarea
                          name="comments"
                          id="comments"
                          rows="2"
                          className={`${
                            isEditable
                              ? 'bg-white'
                              : 'bg-gray-50 cursor-not-allowed'
                          } border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                          onChange={(event) => setComments(event.target.value)}
                          defaultValue={comments}
                          placeholder="Please enter any additional comments here"
                          disabled={!isEditable}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="rfqEmail"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Original RFQ Email
                        </label>
                        <div
                          id="rfqEmail"
                          style={{ minHeight: '3rem' }} // Adjust the value as needed
                          className="bg-gray-50 cursor-not-allowed border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          dangerouslySetInnerHTML={{ __html: cleanRfqEmail }}
                        />
                      </div>
                      <div className="sm:col-span-2 mt-5">
                        <label
                          htmlFor="rfqEmail"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Attachments Data
                        </label>
                        <div
                          id="attachmentsData"
                          style={{ minHeight: '3rem' }} // Adjust the value as needed
                          className="bg-gray-50 cursor-not-allowed border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          dangerouslySetInnerHTML={{
                            __html: cleanAttachmentsData(),
                          }}
                        />
                      </div>
                    </div>
                  </section>
                </>
              )}
              {currentTab === 'Items' && (
                <>
                  <section className="bg-white dark:bg-gray-900">
                    <div className="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                      <button
                        onClick={() =>
                          setContactDetailsVisible(!isContactDetailsVisible)
                        }
                        className="font-bold py-2 cursor-pointer"
                      >
                        Contact Details {isContactDetailsVisible ? '▲' : '▼'}
                      </button>
                      {isContactDetailsVisible && (
                        <div>
                          <p className="mb-2 text-sm">
                            <strong>Contact Name:</strong>{' '}
                            {primaryContactName ? primaryContactName : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Number:</strong>{' '}
                            {primaryPhone ? primaryPhone : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Email:</strong>{' '}
                            {primaryEmail ? primaryEmail : '--'}
                          </p>
                        </div>
                      )}
                    </div>
                    <h5 className="flex justify-between items-center text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      Line Items{' '}
                      <span className="text-sm">
                        (Double click on the Cell to Edit Details)
                      </span>
                    </h5>
                    <div className="block w-full  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                      <ReactDataGrid
                        idProperty="uniqueId"
                        style={gridStyle}
                        onEditComplete={onEditCompleteItemsGrid}
                        editable={true}
                        columns={orderedItemsGridColumns}
                        onColumnOrderChange={(e) =>
                          handleColumnOrderChange(e, 'RfqItems')
                        }
                        dataSource={itemsGridData}
                        pagination
                        onColumnResize={(e) =>
                          handleColumnResize(e, 'RfqItems')
                        }
                        defaultLimit={20}
                        rowHeight={60}
                        enableSelection
                        enableKeyboardNavigation
                      />
                    </div>
                  </section>
                </>
              )}
              {currentTab === 'Attachment' && (
                <>
                  <section className="bg-white dark:bg-gray-900">
                    <div className="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                      <button
                        onClick={() =>
                          setContactDetailsVisible(!isContactDetailsVisible)
                        }
                        className="font-bold py-2 cursor-pointer"
                      >
                        Contact Details {isContactDetailsVisible ? '▲' : '▼'}
                      </button>
                      {isContactDetailsVisible && (
                        <div>
                          <p className="mb-2 text-sm">
                            <strong>Contact Name:</strong>{' '}
                            {primaryContactName ? primaryContactName : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Number:</strong>{' '}
                            {primaryPhone ? primaryPhone : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Email:</strong>{' '}
                            {primaryEmail ? primaryEmail : '--'}
                          </p>
                        </div>
                      )}
                    </div>
                    <h5 className="flex justify-between items-center text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      Attachments{' '}
                      <span className="text-sm">
                        (Double click on the Cell to Edit Details)
                      </span>
                    </h5>
                    <div className="block w-full  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                      <ReactDataGrid
                        idProperty="uniqueId"
                        style={gridStyle}
                        onEditComplete={onEditCompleteAttachmentsGrid}
                        columns={attachmentsGridColumns}
                        dataSource={attachmentsGridData}
                        pagination
                        onColumnResize={(e) =>
                          handleColumnResize(e, 'RfqAttachments')
                        }
                        defaultLimit={20}
                        rowHeight={60}
                        enableSelection
                        enableKeyboardNavigation
                      />
                    </div>
                  </section>
                </>
              )}
              {currentTab === 'Request Quotes' && (
                <>
                  <section className="bg-white dark:bg-gray-900">
                    <div className="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                      <button
                        onClick={() =>
                          setContactDetailsVisible(!isContactDetailsVisible)
                        }
                        className="font-bold py-2 cursor-pointer"
                      >
                        Contact Details {isContactDetailsVisible ? '▲' : '▼'}
                      </button>
                      {isContactDetailsVisible && (
                        <div>
                          <p className="mb-2 text-sm">
                            <strong>Contact Name:</strong>{' '}
                            {primaryContactName ? primaryContactName : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Number:</strong>{' '}
                            {primaryPhone ? primaryPhone : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Email:</strong>{' '}
                            {primaryEmail ? primaryEmail : '--'}
                          </p>
                        </div>
                      )}
                    </div>
                    <h5 className="flex justify-between items-center text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      Request Quotes{' '}
                    </h5>
                    <div className="block w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                      <div className="flex justify-between space-x-2">
                        <div className="w-1/2">
                          <ReactDataGrid
                            idProperty="uniqueId"
                            style={gridStyle}
                            defaultFilterValue={
                              itemsGridRequestQuoteFilterValue
                            }
                            columns={orderedItemsGridRequestQuoteColumns}
                            onColumnResize={(e) => {
                              handleColumnResize(e, 'RfqRequestQuoteItems');
                            }}
                            onColumnOrderChange={(e) =>
                              handleColumnOrderChange(e, 'RfqRequestQuoteItems')
                            }
                            dataSource={items}
                            pagination
                            defaultLimit={20}
                            rowHeight={60}
                            enableSelection
                            enableKeyboardNavigation
                            selected={selectedItems}
                            checkboxColumn
                            onSelectionChange={onSelectionItemsChange}
                          />
                        </div>
                        <div className="w-1/2">
                          <ReactDataGrid
                            idProperty="uniqueId"
                            style={gridStyle}
                            defaultFilterValue={vendorsGridFilterValue}
                            columns={orderedVendorsGridColumns}
                            onColumnResize={(e) => {
                              handleColumnResize(e, 'RfqRequestQuoteVendors');
                            }}
                            onColumnOrderChange={(e) =>
                              handleColumnOrderChange(
                                e,
                                'RfqRequestQuoteVendors'
                              )
                            }
                            dataSource={vendorsData}
                            pagination
                            defaultLimit={20}
                            rowHeight={60}
                            enableSelection
                            enableKeyboardNavigation
                            selected={selectedVendors}
                            checkboxColumn
                            onSelectionChange={onSelectionVendorsChange}
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}
              {currentTab === 'View Bids' && (
                <>
                  <section className="bg-white dark:bg-gray-900">
                    <div className="w-full inline-flex">
                      <div className="block w-1/2 p-6 mx-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                        <button
                          onClick={() =>
                            setContactDetailsVisible(!isContactDetailsVisible)
                          }
                          className="font-bold py-2 cursor-pointer"
                        >
                          Contact Details {isContactDetailsVisible ? '▲' : '▼'}
                        </button>
                        {isContactDetailsVisible && (
                          <div>
                            <p className="mb-2 text-sm">
                              <strong>Contact Name:</strong>{' '}
                              {primaryContactName ? primaryContactName : '--'}
                            </p>
                            <p className="mb-2 text-sm">
                              <strong>Contact Number:</strong>{' '}
                              {primaryPhone ? primaryPhone : '--'}
                            </p>
                            <p className="mb-2 text-sm">
                              <strong>Contact Email:</strong>{' '}
                              {primaryEmail ? primaryEmail : '--'}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="w-1/2 p-6 mx-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                        <strong>{mailData.length || 0}</strong> Emails for Line
                        Items Received out of{' '}
                        <strong>{viewBidsData.length} Total Bids</strong>
                      </div>
                    </div>
                    <h5 className="flex justify-between items-center text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      All Current Bids{' '}
                      <span className="text-sm">
                        (Double click on the Coloured Cells to Edit Details)
                      </span>
                    </h5>
                    <div className="block w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                      <div className="flex justify-between space-x-2">
                        <ReactDataGrid
                          idProperty="uniqueId"
                          style={gridStyle}
                          defaultFilterValue={viewBidsFilterValue}
                          columns={orderedViewBidsColumns}
                          onColumnResize={(e) => {
                            handleColumnResize(e, 'RfqViewBids');
                          }}
                          onColumnOrderChange={(e) =>
                            handleColumnOrderChange(e, 'RfqViewBids')
                          }
                          dataSource={viewBidsData}
                          onEditComplete={onEditCompleteViewBidsGrid}
                          pagination
                          defaultLimit={20}
                          rowHeight={60}
                          enableSelection
                          enableKeyboardNavigation
                          // selected={selectedItems}
                          showZebraRows={false}
                        />
                      </div>
                    </div>
                    {/* <h5 className="flex justify-between items-center text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      Incoming Emails
                    </h5>
                    <div className="block w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                      {mailData.map((email, index) => (
                        <div
                          key={index}
                          className="bg-gray-50 cursor-not-allowed border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          dangerouslySetInnerHTML={{
                            __html: cleanRfqEmail(email.content),
                          }}
                        />
                      ))}
                    </div> */}
                  </section>
                </>
              )}
              {currentTab === 'Y Quotes' && (
                <>
                  <section className="bg-white dark:bg-gray-900">
                    <div className="w-full inline-flex">
                      <div className="block w-full p-6 mx-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                        <button
                          onClick={() =>
                            setContactDetailsVisible(!isContactDetailsVisible)
                          }
                          className="font-bold py-2 cursor-pointer"
                        >
                          Contact Details {isContactDetailsVisible ? '▲' : '▼'}
                        </button>
                        {isContactDetailsVisible && (
                          <div>
                            <p className="mb-2 text-sm">
                              <strong>Contact Name:</strong>{' '}
                              {primaryContactName ? primaryContactName : '--'}
                            </p>
                            <p className="mb-2 text-sm">
                              <strong>Contact Number:</strong>{' '}
                              {primaryPhone ? primaryPhone : '--'}
                            </p>
                            <p className="mb-2 text-sm">
                              <strong>Contact Email:</strong>{' '}
                              {primaryEmail ? primaryEmail : '--'}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <h5 className="flex justify-between items-center text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      Y Quotes{' '}
                      <span className="text-sm">
                        (Double click on the Coloured Cells to Edit Details)
                      </span>
                    </h5>
                    <div className="block w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                      <div className="flex justify-between space-x-2">
                        <ReactDataGrid
                          idProperty="uniqueId"
                          style={gridStyle}
                          enableFiltering
                          defaultFilterValue={saiQuotesFilterValue}
                          columns={orderedSaiQuotesColumns}
                          onColumnResize={(e) => {
                            handleColumnResize(e, 'RfqSaiQuotes');
                          }}
                          onColumnOrderChange={(e) =>
                            handleColumnOrderChange(e, 'RfqSaiQuotes')
                          }
                          dataSource={saiQuotesData}
                          onEditComplete={onEditCompleteViewSaiQuotesGrid}
                          pagination
                          defaultLimit={20}
                          rowHeight={60}
                          enableSelection
                          enableKeyboardNavigation
                          selected={selectedItems}
                          showZebraRows={false}
                        />
                      </div>
                    </div>
                  </section>
                </>
              )}
              {currentTab === 'RFQ Packets' && (
                <>
                  <section className="bg-white dark:bg-gray-900">
                    <div className="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                      <button
                        onClick={() =>
                          setContactDetailsVisible(!isContactDetailsVisible)
                        }
                        className="font-bold py-2 cursor-pointer"
                      >
                        Contact Details {isContactDetailsVisible ? '▲' : '▼'}
                      </button>
                      {isContactDetailsVisible && (
                        <div>
                          <p className="mb-2 text-sm">
                            <strong>Contact Name:</strong>{' '}
                            {primaryContactName ? primaryContactName : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Number:</strong>{' '}
                            {primaryPhone ? primaryPhone : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Email:</strong>{' '}
                            {primaryEmail ? primaryEmail : '--'}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <h5 className="text-lg font-semibold text-gray-900 dark:text-white mb-3">Upload Attachments</h5>
      
      {isUploading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="loader">Loading</div>
        </div>
      )}
      
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Files</label>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
        />
      </div>
      
      <div
        className="border-dashed border-2 border-gray-300 p-6 text-center rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <p className="text-sm text-gray-600 dark:text-gray-300">Drag & Drop Files Here</p>
      </div>
      
      <div className="mt-4">
        {packetsAttachments.map((attachment, index) => (
          <div key={index} className="flex justify-between items-center p-2 border-b border-gray-200 dark:border-gray-600">
            <span className="text-sm text-gray-900 dark:text-white">{attachment.name}</span>
            <div>
              <a
                href={attachment.attachmentUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="py-1 px-3 text-sm font-medium text-blue-600 hover:underline"
              >
                Preview
              </a>
              <button
                onClick={() => handleDeleteAttachment(index)}
                className="ml-3 text-sm text-red-600 hover:underline"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
                    <h5 className="flex justify-between items-center text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      Finalize RFQ Packet{' '}
                    </h5>
                    <div className="block w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                      <div className="flex justify-between space-x-2">
                        <ReactDataGrid
                          idProperty="uniqueId"
                          style={gridStyle}
                          defaultFilterValue={rfqPacketsFilterValue}
                          columns={orderedRfqPacketsColumns}
                          onColumnResize={(e) => {
                            handleColumnResize(e, 'RfqPackets');
                          }}
                          onColumnOrderChange={(e) =>
                            handleColumnOrderChange(e, 'RfqPackets')
                          }
                          dataSource={rfqPacketsGridData}
                          onEditComplete={onEditCompleteViewSaiQuotesGrid}
                          pagination
                          defaultLimit={20}
                          rowHeight={60}
                          enableSelection
                          enableKeyboardNavigation
                          selected={selectedItems}
                          showZebraRows={false}
                        />
                      </div>
                    </div>
                  </section>
                </>
              )}
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              {currentTab === 'RFQ' && (
                <>
                  <button
                    type="button"
                    onClick={isEditable ? saveRfq : () => setIsEditable(true)}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    {isEditable ? 'Save Updated RFQ' : 'Edit RFQ'}
                  </button>
                  {isEditable && (
                    <button
                      onClick={() => {
                        setIsEditable(false);
                      }}
                      type="button"
                      className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Cancel
                    </button>
                  )}
                </>
              )}
              {currentTab === 'Items' && (
                <div className="flex space-x-5">
                  <button
                    type="button"
                    onClick={() => {
                      setItems((prevItems) => [
                        ...prevItems,
                        {
                          itemId: uuidv4(),
                          clin: '',
                          itemName: '',
                          category: '',
                          qty: '',
                          description: '',
                          deliveryAddress: '',
                        },
                      ]);
                    }}
                    className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                  >
                    Add New Items
                  </button>
                  <input
                    ref={excelFileInputRef}
                    className="rounded-lg bg-slate-200 hidden"
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    onChange={handleAddNewItemExcel}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      if (excelFileInputRef.current) {
                        excelFileInputRef.current.click();
                      }
                    }}
                    className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                  >
                    Upload Excel
                  </button>
                </div>
              )}
              {currentTab === 'Request Quotes' && (
                <button
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={() => {
                    if (
                      selectedItemsArray.length > 0 &&
                      selectedVendorsArray.length > 0
                    ) {
                      setModalText(
                        `Are you sure you want to request quotes for ${selectedItemsArray.length} items from ${selectedVendorsArray.length} vendors?`
                      );
                      setIsConfirmModalOpen(true);
                    } else {
                      alert(
                        'Please select at least one item and one vendor to request quotes.'
                      );
                    }
                  }}
                >
                  Request Quotes
                </button>
              )}
              {currentTab === 'View Bids' && (
                <button
                  type="button"
                  onClick={() => setIsAddNewBidModalOpen(true)}
                  className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                >
                  Add New Bid
                </button>
              )}
              {currentTab === 'RFQ Packets' && (
                <>
                  <button
                    type="button"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 mx-3"
                    onClick={async () => {
                      setIsLoading(true);
                      setIsSendRFQPacketEmailModalOpen(true);
                      setIsLoading(false);
                    }}
                  >
                    Send RFQ Packets
                  </button>

                  <button
                    type="button"
                    onClick={() => setIsSendForReviewModalOpen(true)}
                    className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 mx-3"
                  >
                    Send for Review
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        aria-hidden="true"
        className={
          isConfirmModalOpen
            ? 'flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            <button
              type="button"
              className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() => setIsConfirmModalOpen(false)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-green-500 dark:text-green-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Success</span>
            </div>
            <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
              {modalText}
            </p>
            <button
              type="button"
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={confirmRequestQuotesFunction}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>

      <div
        aria-hidden="true"
        className={
          isSendForReviewModalOpen
            ? 'flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            <button
              type="button"
              className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() => setIsSendForReviewModalOpen(false)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-green-500 dark:text-green-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Success</span>
            </div>
            <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
              Are you sure you want to send this RFQ Packet for Review?
            </p>
            <button
              type="button"
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={sendRFQPacketForReview}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>

      <div
        tabIndex="-1"
        aria-hidden="true"
        className={
          isSendRequestQuoteEmailModalOpen
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-7xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Send Request Quote Email
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setIsSendRequestQuoteEmailModalOpen(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* <div className="p-4 md:p-5 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <p className="text-lg mb-5">Dear [Vendor Name],</p>
              <p className="text-lg mb-5">
                I hope this email finds you well. We are interested in sourcing
                products for our company, Serving Again, and we believe your
                expertise and product offerings align with our requirements.
              </p>
              <p className="text-lg mb-5">
                We kindly request you to provide us with a quotation for the
                following items along with detailed information on the shipping
                cost associated with each item to the specified location.
              </p>
              <table className="table-auto w-full mb-6">
                <thead>
                  <tr>
                    <th className="px-4 py-2">CLIN</th>
                    <th className="px-4 py-2">Item Name</th>
                    <th className="px-4 py-2">Description</th>
                    <th className="px-4 py-2">Qty</th>
                    <th className="px-4 py-2">Unit</th>
                    <th className="px-4 py-2">Delivery Address</th>
                    <th className="px-4 py-2">Available Qty</th>
                    <th className="px-4 py-2">Cost</th>
                    <th className="px-4 py-2">S&H</th>
                    <th className="px-4 py-2">Tax (%)</th>
                    <th className="px-4 py-2">Total</th>
                    <th className="px-4 py-2">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedItemsArray.map((item, index) => (
                    <tr key={index}>
                      <td className="border px-4 py-2">{item.clin}</td>
                      <td className="border px-4 py-2">{item.itemName}</td>
                      <td className="border px-4 py-2">{item.description}</td>
                      <td className="border px-4 py-2">{item.qty}</td>
                      <td className="border px-4 py-2">{item.unit}</td>
                      <td className="border px-4 py-2">
                        {item.deliveryAddress}
                      </td>
                      <td className="border px-4 py-2">{item.availableQty}</td>
                      <td className="border px-4 py-2">{item.cost}</td>
                      <td className="border px-4 py-2">{item.shipping}</td>
                      <td className="border px-4 py-2">{item.tax}</td>
                      <td className="border px-4 py-2">{item.total}</td>
                      <td className="border px-4 py-2">{item.comment}</td>
                    </tr>
                  ))}
                  <tr>
                    <td className="border px-4 py-2" colSpan={10}>
                      <strong>Quote ID:</strong> {docId}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="text-lg mb-5">
                Request you to please copy the above Table and fill your
                available values keeping the Quote Id intact as it helps us
                track all requests.
              </p>
              <p className="text-lg mb-5">
                If you have any questions or require further clarification, do
                not hesitate to contact us at{' '}
                <a href="mailto:tinachavous@servingagain.com">
                  tinachavous@servingagain.com
                </a>
                (<a href="tel:9168361344">916-836-1344</a>). We look forward to
                the possibility of working with your esteemed company and
                contributing to our shared goals.
              </p>
              <p className="text-lg mb-5">
                Thank you for your attention to this matter.
              </p>
              <p className="text-lg mb-3">Best Regards</p>
              <p className="text-lg mb-3">Tina Chavous</p>
              <p className="text-lg">
                <a href="tel:9168361344">916-836-1344</a>
              </p>
              <p className="text-lg">
                3609 Bradshaw Rd H318, Sacramento CA 95827
              </p>
              <p className="text-lg">
                <a
                  href="http://www.servingagain.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.servingagain.com
                </a>
              </p>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/serving-again-dev.appspot.com/o/Yoda%20logo.png?alt=media&token=d8439897-cf39-467d-a1fd-229a5d46ad35"
                alt="Serving Again Logo"
                width="200"
                className="mt-3"
              />
              <p className="text-xs mt-5"></p>
            </div> */}
            <div className="mx-5 my-5">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12">
                  <label
                    htmlFor="shipment_subject"
                    className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                  >
                    Choose Template
                  </label>
                  <select
                    id="shipment_subject"
                    className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write the Email Subject Over Here"
                    onChange={(e) => {
                      handleRQTemplateSelection(e.target.value);
                    }}
                  >
                    <option value="">Select Template</option>
                    {emailRQTemplates?.map((template) => {
                      return (
                        <option
                          key={template.emailTempId}
                          value={template.emailTempId}
                        >
                          {template.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <label
                  htmlFor="shipment_to"
                  className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                >
                  To
                </label>
                <div className="col-span-11 mb-4">
                  <ReactMultiEmail
                    placeholder="Input your email"
                    emails={emailsRQ}
                    onChange={(_emails) => {
                      setEmailsRQ(_emails);
                    }}
                    // onFocus={() => setShipmentFocused(true)}
                    // onBlur={() => setShipmentFocused(false)}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div
                          data-tag
                          key={index}
                          data-tag-item
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '5px',
                            border: '1px solid black',
                            borderRadius: '3px',
                          }}
                        >
                          <span>{email}</span>
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 gap-6 mb-6">
                <label
                  htmlFor="shipment_subject"
                  className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="shipment_subject"
                  value={mailRQSubject}
                  className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write the Email Subject Over Here"
                  required
                  onChange={(e) => {
                    setMailRQSubject(e.target.value);
                  }}
                />
              </div>
              <div className="w-full">
                <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_EDITOR}
                  init={{
                    plugins:
                      ' anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                    toolbar:
                      'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                    ai_request: (request, respondWith) =>
                      respondWith.string(() =>
                        Promise.reject('See docs to implement AI Assistant')
                      ),
                  }}
                  value={finalRQMailContent}
                  onEditorChange={(content, editor) => {
                    setfinalRQMailContent(content);
                  }}
                />
              </div>
            </div>
            <div className="w-full mt-4">
              <section className="bg-white dark:bg-gray-900">
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer w-28"
                          onClick={handleSelectAll}
                        >
                          {selectedRFQ.length === attachmentRows.length ? (
                            <img
                              src="/checked.png"
                              alt="Select All"
                              className="w-6 h-6 mx-2"
                            />
                          ) : (
                            <img
                              src="/removed.png"
                              alt="Not Select All"
                              className="w-6 h-6 mx-2"
                            />
                          )}
                        </th>

                        <th scope="col" className="px-2 py-3">
                          Attachment Name
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {attachmentRows.map((row, index) => (
                        <tr key={row.id}>
                          <td
                            onClick={() => toggleSelection(row.name)} // Toggle based on name
                            className="cursor-pointer w-28"
                          >
                            {selectedRFQ.includes(row.name) ? (
                              <img
                                src="/checked.png"
                                alt="Selected"
                                className="w-6 h-6 mx-6 my-2"
                              />
                            ) : (
                              <img
                                src="/removed.png"
                                alt="Not Selected"
                                className="w-6 h-6 mx-6 my-2"
                              />
                            )}
                          </td>

                          <td>
                            <input
                              type="text"
                              placeholder="Attachment Name"
                              value={row.name || ''}
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-0 my-2"
                              onChange={() => {}} // Add this to prevent console errors about controlled components
                            />
                          </td>
                          {/* <td>
                            {row.attachmentUrl ? (
                              <a
                                href={row.attachmentUrl}
                                rel="noreferrer"
                                target="_blank"
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mx-4 my-2 px-4 py-2"
                              >
                                {row.attachmentUrl}
                              </a>
                            ) : (
                              <span className="text-gray-500">
                                No URL Provided
                              </span>
                            )}
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={sendRequestQuotesEmails}
              >
                Send {selectedVendorsArray.length}{' '}
                {selectedVendorsArray.length > 1 ? 'Emails' : 'Email'}
              </button>
              <button
                onClick={() => {
                  setIsSendRequestQuoteEmailModalOpen(false);
                }}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        tabIndex="-1"
        aria-hidden="true"
        className={
          isSendRFQPacketEmailModalOpen
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-7xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Send RFQ Packet Email
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setIsSendRFQPacketEmailModalOpen(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 overflow-y-auto max-h-[calc(100vh-15rem)]">
              {combinedClinData.map((item, index) => {
                if (item.qty !== item.saiQty) {
                  return (
                    <div
                      key={index}
                      className="flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
                      role="alert"
                    >
                      <svg
                        className="flex-shrink-0 inline w-4 h-4 me-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                      </svg>
                      <span className="sr-only">Info</span>
                      <div>
                        <strong>
                          CLIN {item.clin} ({item.name}){' '}
                        </strong>{' '}
                        has <strong>{item.qty}</strong> required quantities but{' '}
                        <strong>{item.saiQty}</strong> Y quantities.
                      </div>
                    </div>
                  );
                }
                return null;
              })}

              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12">
                  <label
                    htmlFor="shipment_subject"
                    className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                  >
                    Choose Template
                  </label>
                  <select
                    id="shipment_subject"
                    className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write the Email Subject Over Here"
                    onChange={(e) => {
                      handleTemplateSelection(e.target.value);
                    }}
                  >
                    <option value="">Select Template</option>
                    {emailTemplates?.map((template) => {
                      return (
                        <option
                          key={template.emailTempId}
                          value={template.emailTempId}
                        >
                          {template.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <label
                  htmlFor="shipment_to"
                  className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                >
                  To
                </label>
                <div className="col-span-11 mb-4">
                  <ReactMultiEmail
                    placeholder="Input your email"
                    emails={emails}
                    onChange={(_emails) => {
                      setEmails(_emails);
                    }}
                    // onFocus={() => setShipmentFocused(true)}
                    // onBlur={() => setShipmentFocused(false)}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div
                          data-tag
                          key={index}
                          data-tag-item
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '5px',
                            border: '1px solid black',
                            borderRadius: '3px',
                          }}
                        >
                          <span>{email}</span>
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 gap-6 mb-6">
                <label
                  htmlFor="shipment_subject"
                  className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="shipment_subject"
                  value={mailSubject}
                  className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write the Email Subject Over Here"
                  required
                  onChange={(e) => {
                    setMailSubject(e.target.value);
                  }}
                />
              </div>
              <div className="w-full">
                <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_EDITOR}
                  init={{
                    plugins:
                      ' anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                    toolbar:
                      'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                    ai_request: (request, respondWith) =>
                      respondWith.string(() =>
                        Promise.reject('See docs to implement AI Assistant')
                      ),
                  }}
                  value={finalMail}
                  onEditorChange={(content, editor) => {
                    setFinalMail(content);
                  }}
                />
              </div>
              <div className="w-full mt-4">
                <section className="bg-white dark:bg-gray-900">
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th
                            scope="col"
                            className="px-4 py-3 cursor-pointer w-28"
                            onClick={handleSelectAll}
                          >
                            {selectedRFQ?.length === attachmentRows?.length ? (
                              <img
                                src="/checked.png"
                                alt="Select All"
                                className="w-6 h-6 mx-2"
                              />
                            ) : (
                              <img
                                src="/removed.png"
                                alt="Not Select All"
                                className="w-6 h-6 mx-2"
                              />
                            )}
                          </th>

                          <th scope="col" className="px-6 py-3">
                            Attachment Name
                          </th>
                          {/* <th scope="col" className="px-6 py-3">
                            Attachment
                          </th> */}
                          {/* <th scope="col" className="px-6 py-3 text-center">
                            <div className="">
                              <label htmlFor="states" className="sr-only">
                                Choose a Existing File
                              </label>
                              <select
                                onChange={handleAttachmentChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg border-s-2 focus:ring-blue-500 focus:border-blue-500 w-fit p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              >
                                {attachments.map((attachment, index) => {
                                  const label =
                                    attachment.fileName ||
                                    attachment.name ||
                                    `File-${Math.floor(
                                      100 + Math.random() * 900
                                    )}`;

                                  return (
                                    <option key={index} value={label}>
                                      {label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {attachmentRows.map((row, index) => (
                          <tr key={row.id}>
                            <td
                              onClick={() => toggleSelection(row.name)}
                              className="cursor-pointer w-28"
                            >
                              {selectedRFQ.includes(row.name) ? (
                                <img
                                  src="/checked.png"
                                  alt="Selected"
                                  className="w-6 h-6 mx-6 my-2"
                                />
                              ) : (
                                <img
                                  src="/removed.png"
                                  alt="Not Selected"
                                  className="w-6 h-6 mx-6 my-2"
                                />
                              )}
                            </td>

                            <td>
                              <input
                                type="text"
                                placeholder="Attachment Name"
                                value={row.name || ''}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2"
                                onChange={() => {}} // Add this to prevent console errors about controlled components
                              />
                            </td>
                            {/* <td>
                              {row.attachmentUrl ? (
                                <a
                                  href={row.attachmentUrl}
                                  rel="noreferrer"
                                  target="_blank"
                                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mx-4 my-2 px-4 py-2"
                                >
                                  {row.attachmentUrl}
                                </a>
                              ) : (
                                <span className="text-gray-500">
                                  No URL Provided
                                </span>
                              )}
                            </td> */}
                            {/* <td className="text-center">
                              <button
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      'Are you sure you want to delete this row?'
                                    )
                                  ) {
                                    handleDeleteAttachmentRow(index);
                                  }
                                }}
                                className="text-red-500 hover:text-red-700 text-center"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6 mx-auto"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                  />
                                </svg>
                              </button>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={() => sendRFQPacketEmail()}
              >
                Send Email
              </button>
              <button
                onClick={() => {
                  setIsSendRFQPacketEmailModalOpen(false);
                }}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        tabIndex="-1"
        aria-hidden="true"
        className={
          isAddNewBidModalOpen
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-4xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Add a New Bid
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setIsAddNewBidModalOpen(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                <label
                  htmlFor="items"
                  className="inline-flex mb-2 font-medium text-gray-900 dark:text-white items-center"
                >
                  Select the Item you want to set the Bid for:
                </label>
                <select
                  id="items"
                  onChange={(e) => setSelectedNewBidItemId(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option>Choose an item</option>
                  {items.map((item) => (
                    <option key={item.itemId} value={item.itemId}>
                      {item.clin}: {item.itemName}
                    </option>
                  ))}
                </select>

                <label
                  htmlFor="vendor"
                  className="inline-flex mb-2 font-medium text-gray-900 dark:text-white items-center"
                >
                  Select the Vendor:
                </label>
                <select
                  id="vendor"
                  onChange={(e) => setSelectedNewBidVendorId(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option>Choose an Vendor</option>
                  {vendorsData.map((vendor) => (
                    <option key={vendor.vendorId} value={vendor.vendorId}>
                      {vendor.vendorName} ({vendor.primaryEmail})
                    </option>
                  ))}
                </select>

                <label
                  htmlFor="vendor_qty"
                  className="block mb-2 items-center font-medium text-gray-900 dark:text-white"
                >
                  Vendor Quantity <span className="text-red-500">*</span>
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    (Required Quantity is{' '}
                    <strong className="text-md">
                      {' '}
                      {
                        (
                          items.find(
                            (item) => item.itemId === selectedNewBidItemId
                          ) || {}
                        ).qty
                      }
                    </strong>
                    )
                  </span>
                </label>
                <input
                  type="text"
                  id="vendor_qty"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Vendor Quantity"
                  value={vendorAddQty}
                  onChange={(e) => setVendorAddQty(e.target.value)}
                  required
                />

                <label
                  htmlFor="vendor_price"
                  className="block mb-2 items-center font-medium text-gray-900 dark:text-white"
                >
                  Vendor Price <span className="text-red-500">*</span>
                </label>
                <div className="flex">
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                    $
                  </span>
                  <input
                    type="text"
                    id="vendor_price"
                    className="rounded-none rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Vendor Price"
                    value={vendorAddPrice}
                    onChange={(e) => setVendorAddPrice(e.target.value)}
                    required
                  />
                </div>

                <label
                  htmlFor="vendor_SAndH"
                  className="block mb-2 items-center font-medium text-gray-900 dark:text-white"
                >
                  Vendor S&H <span className="text-red-500">*</span>
                </label>
                <div className="flex">
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                    $
                  </span>
                  <input
                    type="text"
                    id="vendor_SAndH"
                    className="rounded-none rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Vendor S&H"
                    value={vendorAddSAndH}
                    onChange={(e) => setVendorAddSAndH(e.target.value)}
                    required
                  />
                </div>

                <label
                  htmlFor="vendor_tax"
                  className="block mb-2 items-center font-medium text-gray-900 dark:text-white"
                >
                  Vendor Tax <span className="text-red-500">*</span>
                </label>
                <div className="flex">
                  <input
                    type="text"
                    id="vendor_tax"
                    className="rounded-none rounded-s-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Vendor Tax"
                    value={vendorAddTax}
                    onChange={(e) => setVendorAddTax(e.target.value)}
                    required
                  />
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-s-0 border-gray-300 rounded-e-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                    %
                  </span>
                </div>

                <label
                  htmlFor="vendor_total"
                  className="block mb-2 items-center font-medium text-gray-900 dark:text-white"
                >
                  Vendor Total <span className="text-red-500">*</span>
                </label>
                <div className="flex">
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                    $
                  </span>
                  <input
                    type="text"
                    id="vendor_total"
                    className="rounded-none rounded-e-lg bg-gray-100 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Vendor Total"
                    value={vendorAddTotal}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={manualAddBid}
              >
                Add New Bid
              </button>
              <button
                onClick={() => {
                  setIsAddNewBidModalOpen(false);
                }}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <PreviewFileModal
        openModal={openFilePreviewModal}
        setOpenModal={setOpenFilePreviewModal}
        fileLink={previewFileUrl}
        attachments={attachments}
      />

      <div
        id="addUserData-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          mappingModalOpen
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Select Headers
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => {
                  setMappingModalOpen(false);
                  if (excelFileInputRef.current) {
                    excelFileInputRef.current.value = null;
                  }
                }}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4  h-[calc(85vh)]">
              <div className="border-b border-gray-200 dark:border-gray-700 flex justify-center h-full overflow-scroll">
                <form className="w-full">
                  <div className="grid grid-cols-2 border-b border-slate-700 pb-2 mx-10 font-semibold text-lg">
                    <div>Headers</div>
                    <div>Select Excel Headers</div>
                  </div>
                  {[
                    'clin',
                    'itemName',
                    'category',
                    'qty',
                    'description',
                    'deliveryAddress',
                    'unit',
                    'comment',
                  ].map((option) => (
                    <div
                      key={option}
                      className="grid grid-cols-2 space-y-2 items-center py-2 border-b border-slate-300 mx-10"
                    >
                      <label>{option}</label>
                      <select
                        className="rounded-lg bg-slate-200"
                        value={selectedMapping[option] || ''}
                        onChange={(e) =>
                          handleSelectChange(option, e.target.value)
                        }
                      >
                        <option value="">Select Header</option>
                        {headers.map((header) => (
                          <option key={header} value={header}>
                            {header}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
                  <div className="w-full flex justify-center my-5">
                    <button
                      type="button"
                      className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                      onClick={() => {
                        // console.log(selectedMapping);
                        setMappingModalOpen(false);
                        processExcelFile();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewRfqComponent;
