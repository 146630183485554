import { PencilSquareIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { deleteDelivery } from '../../database/set-delivery-data';
import { useState } from 'react';
import Modal from "react-modal"; 

const getStatusProps = (value) => {
  switch (value) {
    case 'Delivered':
      return {
        badgeClass:
          'bg-green-500 text-white px-2 py-1 rounded-full text-center mx-5',
        displayValue: value,
      };
    case 'Not Shipped':
    case 'Pending':
      return {
        badgeClass:
          'bg-red-500 text-white px-2 py-1 rounded-full text-center mx-5',
        displayValue: 'Not Shipped',
      };
    case 'Shipped':
    case 'In Transit':
    case 'Out for Delivery':
    case 'In Route':
      return {
        badgeClass:
          'bg-yellow-500 text-white px-2 py-1 rounded-full text-center mx-5',
        displayValue: 'Shipped',
      };
    default:
      return {
        badgeClass:
          'bg-red-500 text-white px-2 py-1 rounded-full text-center mx-5',
        displayValue: 'Not Shipped',
      };
  }
};

const StatusCell = ({ value }) => {
  const { badgeClass, displayValue } = getStatusProps(value);

  return <div className={badgeClass}>{displayValue}</div>;
};

const TrackingIDCell = ({ value, data }) => {
  const trackingUrlGenerators = {
    FEDEX: (trackingNumber) =>
      `https://www.fedex.com/apps/fedextrack/?tracknumbers=${trackingNumber}`,
    USPS: (trackingNumber) =>
      `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`,
    UPS: (trackingNumber) =>
      `https://www.ups.com/track?loc=null&tracknum=${trackingNumber}`,
    DHL: (trackingNumber) =>
      `http://www.dhl.com/en/express/tracking.html?AWB=${trackingNumber}`,
    AMAZON: (trackingNumber) =>
      `https://www.amazon.com/progress-tracker/package/ref=ppx_yo_dt_b_track_package?_encoding=UTF8&itemId=&orderId=${trackingNumber}&packageIndex=0&shipmentId=`,
  };

  const trackingUrlGenerator =
    trackingUrlGenerators[data.trackingPartner.toUpperCase()];

  return trackingUrlGenerator ? (
    <a
      href={trackingUrlGenerator(value)}
      target="_blank"
      className="text-blue-700 underline hover:text-blue-900"
      rel="noopener noreferrer"
    >
      Track: {value}
    </a>
  ) : (
    value
  );
};

const viewRenderer = ({ data, setOpenEditModal, setSelectedId }) => {
  const handleClick = (event) => {
    const docId = event.currentTarget.getAttribute('data-doc-id');
    // console.log('docId', docId);
    setSelectedId(docId); // Set the selected id
    setOpenEditModal(true); // Open the modal
  };

  return (
    <div
      className="flex justify-center"
      data-doc-id={data.data.uniqueId}
      onClick={handleClick}
    >
      <PencilSquareIcon className="h-5 w-5 hover:text-blue-500 cursor-pointer" />
    </div>
  );
};

const onDeleteRenderer = ({ data }) => {
  // // console.log('idData: ', data);

  return (
    <button
      type="button"
      onClick={() => deleteDelivery(data.uniqueId)}
      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
    >
      Delete
    </button>
  );
};

const onInvoiceGenerateRenderer = ({
  data,
  setOpenGenerateInvoicModal,
  setInvoiceSelectedId,
  setSelectedInvoiceRow,
}) => {
  // // console.log('idData: ', data);

  return (
    <button
      type="button"
      onClick={() => {
        setInvoiceSelectedId(data.uniqueId);
        setSelectedInvoiceRow(data);
        setOpenGenerateInvoicModal(true);
      }}
      className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
    >
      Generate Invoice
    </button>
  );
};

const orderColumns = (columns, order) => {
  if (!order) return columns;

  const orderedColumns = [];
  order.forEach((columnName) => {
    const column = columns.find((col) => col.name === columnName);
    if (column) {
      orderedColumns.push(column);
    }
  });

  // Add any remaining columns that are not specified in the order
  columns.forEach((column) => {
    if (!order.includes(column.name)) {
      orderedColumns.push(column);
    }
  });

  return orderedColumns;
};

// const ReadMore = ({ text, limit = 20 }) => {
//   const [expanded, setExpanded] = useState(false);

//   if (!text || text.trim() === "") return "No comments";

//   return (
//     <div>
//       {expanded ? text : `${text.substring(0, limit)}... `}
//       {text.length > limit && (
//         <button
//           className="text-blue-500 underline hover:text-blue-700"
//           onClick={() => setExpanded(!expanded)}
//         >
//           {expanded ? "Read Less" : "Read More"}
//         </button>
//       )}
//     </div>
//   );
// };

// ReadMore component with modal
const ReadMoreModal = ({ text, limit = 20 }) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!text || text.trim() === "") return "No comments";

  return (
    <>
      <div className="truncate max-w-[250px] overflow-hidden text-ellipsis">
        {text.length > limit ? `${text.substring(0, limit)}... ` : text}
        {text.length > limit && (
          <button
            className="text-blue-500 underline hover:text-blue-700"
            onClick={() => setIsOpen(true)}
          >
            Read More
          </button>
        )}
      </div>

      {/* Modal to show full comment */}
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-96 max-w-full">
          <h2 className="text-lg font-bold mb-4">Full Comment</h2>
          <p className="text-gray-700">{text}</p>
          <button
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={() => setIsOpen(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

const deliveryColumns = (
  setOpenEditModal,
  setSelectedId,
  tablePreferences = {},
  setInvoiceSelectedId,
  setOpenGenerateInvoicModal,
  setSelectedInvoiceRow
) => {
  const columns = [
    {
      name: 'deliveryDate',
      header: 'Delivery Date',
      minWidth: 100,
      ...(tablePreferences.deliveryDate && {
        defaultWidth: tablePreferences.deliveryDate,
      }),
    },
    {
      name: 'spoId',
      header: 'SPO#',
      minWidth: 250,
      ...(tablePreferences.spoId && { defaultWidth: tablePreferences.spoId }),
    },
    {
      name: 'vpoId',
      header: 'VPO#',
      minWidth: 250,
      ...(tablePreferences.vpoId && { defaultWidth: tablePreferences.vpoId }),
    },
    {
      name: 'vendorName',
      header: 'Vendor Name',
      minWidth: 200,
      ...(tablePreferences.vendorName && {
        defaultWidth: tablePreferences.vendorName,
      }),
    },
    {
      name: 'vendorEmail',
      header: 'Vendor Email',
      minWidth: 200,
      ...(tablePreferences.vendorEmail && {
        defaultWidth: tablePreferences.vendorEmail,
      }),
    },
    {
      name: 'delQty',
      header: 'Shipped Qty',
      minWidth: 150,
      ...(tablePreferences.delQty && { defaultWidth: tablePreferences.delQty }),
    },
    {
      name: 'saiQty',
      header: 'Y Qty',
      minWidth: 150,
      ...(tablePreferences.saiQty && { defaultWidth: tablePreferences.saiQty }),
    },
    {
      name: 'trackingPartner',
      header: 'Tracking Partner',
      minWidth: 200,
      ...(tablePreferences.trackingPartner && {
        defaultWidth: tablePreferences.trackingPartner,
      }),
    },
    {
      name: 'trackingID',
      header: 'Tracking ID',
      minWidth: 250,
      render: TrackingIDCell,
      ...(tablePreferences.trackingID && {
        defaultWidth: tablePreferences.trackingID,
      }),
    },
    {
      name: 'status',
      header: 'Status',
      minWidth: 200,
      render: StatusCell,
      ...(tablePreferences.status && { defaultWidth: tablePreferences.status }),
    },
    {
      name: 'updatedAt',
      header: 'Updated At',
      minWidth: 200,
      ...(tablePreferences.updatedAt && {
        defaultWidth: tablePreferences.updatedAt,
      }),
    },
    {
      name: "comments",
      header: "Comments",
      minWidth: 300,
      render: ({ value }) => <ReadMoreModal text={value} />,
      ...(tablePreferences.comments && { defaultWidth: tablePreferences.comments }),
    },
    {
      name: 'update',
      header: 'Update delivery',
      minWidth: 100,
      render: (data) => viewRenderer({ data, setOpenEditModal, setSelectedId }),
      ...(tablePreferences.update && { defaultWidth: tablePreferences.update }),
    },
    {
      name: 'invoiceGenerate',
      header: 'Generate Invoice',
      minWidth: 50,
      render: ({ value, data }) =>
        onInvoiceGenerateRenderer({
          data,
          setOpenGenerateInvoicModal,
          setSelectedInvoiceRow,
          setInvoiceSelectedId,
        }),
      ...(tablePreferences.invoiceGenerate && {
        defaultWidth: tablePreferences.invoiceGenerate,
      }),
    },
    {
      name: 'delete',
      header: 'Delete',
      minWidth: 50,
      render: ({ value, data }) => onDeleteRenderer({ data }),
      ...(tablePreferences.delete && { defaultWidth: tablePreferences.delete }),
    },
  ];

  const order = tablePreferences?.order;

  // Order the columns
  const orderedColumns = orderColumns(columns, order);

  return orderedColumns;
};
export default deliveryColumns;
