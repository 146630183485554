import React, { useState, useEffect, Suspense } from 'react';
import 'react-quill/dist/quill.snow.css';
import Loader from '../loader/loader.js';
import '@inovua/reactdatagrid-community/index.css';
import { fetchSingleDeliveryData } from '../../database/fetch-deliveries-data';
import { updateDelivery } from '../../database/set-delivery-data';
import { updatepos } from '../../database/set-po-data.js';
import { errorAlert, successAlert } from '../alerts.js';
import { fetchAllRfqData } from '../../database/fetch-rfq-data.js';
import { fetchAllPosData } from '../../database/fetch-pos-data.js';

function ViewDeliveryComponent({ openEditModal, setOpenEditModal, docId }) {
  // // console.log(docId);
  const [isLoading, setIsLoading] = useState(false);
  const [posData, setPosData] = useState([]);
  const [spoNo, setSpoNo] = useState('');
  const [vpoNo, setVpoNo] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [saiQty, setSaiQty] = useState('');
  const [deliveryQty, setDeliveryQty] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [trackingPartner, setTrackingPartner] = useState('');
  const [trackingStatus, setTrackingStatus] = useState('');
  const [trackingID, setTrackingID] = useState('');
  const [rfqs, setRfqs] = useState([]);
  const [linkedRfqNo, setLinkedRfqNo] = useState('');
  const [selectedPo, setSelectedPo] = useState([]);
  const [filteredVPOs, setFilteredVPOs] = useState([]);
  const [comments, setComments] = useState('');
  const [lineItems, setLineItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const unsubscribe = fetchAllPosData((pos) => {
        // console.log('POSSS', pos);
        setPosData(pos);
        setIsLoading(false);
      });

      return () => {
        unsubscribe();
      };
    };

    fetchData().then(() => console.log('Received Data'));
  }, []);

  useEffect(() => {
    const fetchDeliveryData = async () => {
      setIsLoading(true);
      const data = await fetchSingleDeliveryData(docId);
      // console.log('Single Delivery Data: ', data);
      const relatedPos = await posData.filter(
        (pos) => pos.spo === data.spo || pos.spo === data.spoId
      );
      setSelectedPo(relatedPos);
      setFilteredVPOs(
        relatedPos[0]?.originalQuotes.filter((vpo) => Number(vpo.saiQty) > 0)
      );
      setComments(data.comments || '');
      setSpoNo(data.spo || data.spoId);
      setVpoNo(data.vpoId);
      setVendorName(data.vendorName);
      setSaiQty(data.saiQty);
      setDeliveryQty(data.deliveryQty);
      setDeliveryDate(data.deliveryDate);
      setTrackingPartner(data.trackingPartner);
      setTrackingStatus(data.deliveryStatus);
      setTrackingID(data.trackingID);
      setLinkedRfqNo(data.rfqNo || '');
      setIsLoading(false);
    };

    if (docId) {
      fetchDeliveryData();
    }
  }, [docId, posData]);

  useEffect(() => {
    const unsubscribe = fetchAllRfqData((rfqs) => {
      // console.log(rfqs);
      setRfqs(rfqs);
    });
    return () => unsubscribe();
  }, []);

  const updateDeliveryStatus = async () => {
    // console.log('selected PO: ', selectedPo);
    const formData = {
      ...selectedPo[0],
      spoId: spoNo,
      vpoId: vpoNo,
      vendorName: vendorName,
      saiQty,
      deliveryDate,
      trackingPartner,
      deliveryQty,
      trackingID,
      deliveryStatus: trackingStatus,
      comments,
    };

    // console.log(formData);

    try {
      setIsLoading(true);
      await updateDelivery(formData, docId);
      setIsLoading(false);
      setOpenEditModal(false);
    } catch (error) {
      console.error('Error creating delivery:', error);
    }
  };

  const handleSpoChange = (e) => {
    setSpoNo(e.target.value);
    setVendorName('');
    setVpoNo('');
    setSaiQty('');
    const relatedPos = posData.filter((pos) => pos.spo === e.target.value);
    setSelectedPo(relatedPos);
    setFilteredVPOs(
      relatedPos[0]?.originalQuotes.filter((vpo) => Number(vpo.saiQty) > 0)
    );
  };
  const fetchLineItems = () => {
    if (!vpoNo) {
      setLineItems([]);
      return;
    }

    const matchingItems =
      selectedPo[0]?.originalQuotes.filter((item) => item.vpo === vpoNo) || [];
    if (matchingItems.length > 0) {
      setLineItems(matchingItems.map((item) => item.itemName));
    } else {
      setLineItems([]);
    }
  };

  const handleVpoChange = (e) => {
    setVpoNo(e.target.value);
    const selectedItem = selectedPo[0].originalQuotes.filter(
      (item) => item.vpo === e.target.value
    );
    setVendorName(selectedItem[0].vendorName);
    setSaiQty(selectedItem[0].saiQty);
  };

  return (
    <>
      {isLoading && <Loader />}

      <div
        id="addconfirmPOData-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          openEditModal
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-5xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Update Delivery Status
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setOpenEditModal(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="spoNo"
                    className="block mb-2 text-sm col-span-1 font-medium text-gray-900 dark:text-white"
                  >
                    SPO#
                    <select
                      // type="text"
                      name="spoNo"
                      id="spoNo"
                      value={spoNo}
                      className="bg-gray-100 cursor-pointer border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      required
                      onChange={handleSpoChange}
                    >
                      {posData.map((pos, index) => {
                        return (
                          <option key={index} value={pos.spo}>
                            {pos.spo}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="vpoNo"
                    className="block mb-2 text-sm col-span-1 font-medium text-gray-900 dark:text-white"
                  >
                    VPO#
                    <select
                      name="vpoNo"
                      id="vpoNo"
                      value={vpoNo}
                      className="bg-gray-100 cursor-pointer border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      required
                      onChange={handleVpoChange}
                    >
                      <option value="">Select Vpo</option>
                      {filteredVPOs.map((item, index) => {
                        return (
                          <option key={index} value={item.vpo}>
                            {item.vpo} {item.itemName} {item.vendorName || ''}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="vendorName"
                    className="block mb-2 text-sm col-span-1 font-medium text-gray-900 dark:text-white"
                  >
                    Vendor Name
                    <input
                      type="text"
                      list="vendor-name"
                      name="vendorName"
                      id="vendorName"
                      className="bg-gray-100 cursor-not-allowed border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      required
                      value={vendorName}
                      disabled
                    />
                  </label>
                </div>
                <label
                  htmlFor="saiQty"
                  className="block mb-2 text-sm col-span-1 font-medium text-gray-900 dark:text-white"
                >
                  Y Qty
                  <input
                    type="number"
                    name="saiQty"
                    id="deliveryQty"
                    className="bg-gray-100  border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    value={saiQty}
                    // onChange={(e) => setSaiQty(e.target.value)}
                    disabled
                  />
                </label>
                <label
                  htmlFor="deliveryQty"
                  className="block mb-2  text-sm col-span-1 font-medium text-gray-900 dark:text-white"
                >
                  Delivery Qty (Number of Units of Line Item being Delivered)
                  <span className="text-red-600">*</span>
                  <input
                    type="number"
                    name="deliveryQty"
                    id="deliveryQty"
                    className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    required
                    value={deliveryQty}
                    onChange={(e) => {
                      setDeliveryQty(e.target.value);
                    }}
                  />
                </label>
                <label
                  htmlFor="deliveryDate"
                  className="block mb-2  text-sm col-span-1 font-medium text-gray-900 dark:text-white"
                >
                  Delivery Date
                  <span className="text-red-600">*</span>
                  <input
                    type="date"
                    name="deliveryDate"
                    id="deliveryDate"
                    className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    required
                    value={deliveryDate}
                    onChange={(e) => {
                      setDeliveryDate(e.target.value);
                    }}
                  />
                </label>

                <label
                  htmlFor="deliveryPartner"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Delivery Partner <span className="text-red-600">*</span>
                  <input
                    list="delivery-partners"
                    name="deliveryPartner"
                    id="deliveryPartner"
                    className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="FedEx"
                    required
                    value={trackingPartner}
                    onChange={(e) => {
                      setTrackingPartner(e.target.value);
                    }}
                  />
                  <datalist id="delivery-partners">
                    <option value="FedEx"></option>
                    <option value="USPS"></option>
                    <option value="UPS"></option>
                    <option value="DHL"></option>
                    <option value="Amazon"></option>
                  </datalist>
                </label>

                <label
                  htmlFor="status"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Tracking Status <span className="text-red-600">*</span>
                  <select
                    name="status"
                    id="status"
                    className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    required
                    value={trackingStatus}
                    onChange={(e) => {
                      setTrackingStatus(e.target.value);
                    }}
                  >
                    <option value="">Select tracking status</option>
                    <option value="Shipped">Shipped</option>
                    <option value="Not Shipped">Not Shipped</option>
                    <option value="Delivered">Delivered</option>
                  </select>
                </label>

                <label
                  htmlFor="trackingId"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Tracking ID <span className="text-red-600">*</span>
                  <input
                    type="text"
                    name="trackingId"
                    id="trackingId"
                    className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="123456789"
                    required
                    value={trackingID}
                    onChange={(e) => {
                      setTrackingID(e.target.value);
                    }}
                  />
                </label>
                <div className="grid grid-cols-2 gap-4 sm:col-span-2">
                  {/* Left side: Show Line Items button and list */}
                  <div className="w-full">
                    <label htmlFor="comments" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Line Items
                    </label>
                    <button
                      onClick={fetchLineItems}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 
      font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 
      dark:focus:ring-blue-800 h-10 w-full"
                    >
                      Show Line Items
                    </button>
                    {lineItems.length > 0 ? (
                      <ul className="mt-4 bg-gray-100 p-3 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 max-h-32 overflow-y-auto">
                        {lineItems.map((item, index) => (
                          <li key={index} className="text-gray-900 dark:text-white">
                            {item}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">No line items found.</p>
                    )}
                  </div>

                  {/* Right side: Comments text area */}
                  <div className="w-full">
                    <label htmlFor="comments" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Comments
                    </label>
                    <textarea
                      name="comments"
                      id="comments"
                      rows="5"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 
      focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
      dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    ></textarea>
                  </div>
                </div>


              </div>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                onClick={updateDeliveryStatus}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Update Delivery Records
              </button>
              <button
                onClick={() => setOpenEditModal(false)}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewDeliveryComponent;
