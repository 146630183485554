import React, { useState, useEffect, useMemo } from 'react';
import 'react-quill/dist/quill.snow.css';
import Loader from '../loader/loader.js';
import '@inovua/reactdatagrid-community/index.css';
import { fetchSingleDeliveryData } from '../../database/fetch-deliveries-data';
import { errorAlert, successAlert } from '../alerts.js';
import { fetchAllInvoiceTemplates } from '../../database/fetch-invoice-template.js';
import { updateDeliveryAttachments } from '../../database/set-delivery-data.js';
import {
  Tailwind,
  compile,
  Footnote,
  PageBottom,
  CSS,
} from '@fileforge/react-print';

function GenerateInvoiceModal({
  openGenerateInvoicModal,
  setOpenGenerateInvoicModal,
  docId,
  selectedInvoiceRow,
}) {
  let val_start = ''; // Variable to store the initial value of the contentEditable element.

  const [isLoading, setIsLoading] = useState(false);
  const [invoiceTemplates, setInvoiceTemplates] = useState([]);
  const [selectedInvoiceTemplate, setSelectedInvoiceTemplate] = useState('');
  const [invoiceUrl, setInvoiceUrl] = useState('');
  const [selectedTemplete, setSelectedTemplete] = useState({});
  const [lineItems, setLineItems] = useState([]);
  const [selectedLineItems, setSelectedLineItems] = useState([]);

  useEffect(() => {
    // console.log('selectedLineItems', selectedLineItems);
  }, [selectedLineItems]);

  const { total, tax, subtotal } = useMemo(() => {
    let total = 0;
    let tax = 0;

    selectedLineItems.forEach((itemId) => {
      const item = lineItems.find((lineItem) => lineItem.uniqueId === itemId);
      if (item) {
        const itemTotal = parseFloat(item['saiTotal'] || 0);
        const itemTax =
          ((parseFloat(item['tax'] || 0) + parseFloat(item['saiTax'] || 0)) *
            itemTotal) /
          100; // Summing both tax and saiTax percentages of the total

        total += itemTotal;
        tax += itemTax;
      }
    });

    const subtotal = total - tax;

    return { total, tax, subtotal };
  }, [selectedLineItems, lineItems]); // Only recalculate when these dependencies change

  useEffect(() => {
    fetchAllInvoiceTemplates((templates) => {
      setInvoiceTemplates(templates);
    });
  }, [docId, selectedInvoiceTemplate]);

  const removeItem = (itemId) => {
    setSelectedLineItems((prevItems) =>
      prevItems.filter((item) => item !== itemId)
    );
  };

  const handleOnFocus = (e) => {
    //resets the value of val_start to the current value of the contentEditable element.
    // console.log(selectedTemplete);
    val_start = e.target.innerHTML;
  };

  const handleOnBlur = (e, section, field) => {
    //checks if there is a change in the value of the contentEditable element and updates state if there is a change.
    if (val_start !== e.target.innerHTML) {
      setSelectedTemplete((prevTemplate) => ({
        ...prevTemplate,
        template: {
          ...prevTemplate.template,
          [section]: {
            ...prevTemplate.template[section],
            [field]: e.target.innerHTML,
          },
        },
      }));
    }
  };

  const handleOnBlurAddress = (e, section, field1, field2, field3) => {
    //checks if there is a change in the value of the contentEditable element and updates state if there is a change.
    if (val_start !== e.target.innerHTML) {
      const address = e.target.innerHTML.split(',');
      setSelectedTemplete((prevTemplate) => ({
        ...prevTemplate,
        template: {
          ...prevTemplate.template,
          [section]: {
            ...prevTemplate.template[section],
            [field1]: address[0],
            [field2]: address[1],
            [field3]: address[2],
          },
        },
      }));
    }
  };

  const handleOnBlurTable = (e, index) => {
    //checks if there is a change in the value of the contentEditable element and updates state if there is a change.
    if (val_start !== e.target.innerHTML) {
      setSelectedTemplete((prevTemplate) => ({
        ...prevTemplate,
        template: {
          ...prevTemplate.template,
          table: {
            ...prevTemplate.template.table,
            columns: prevTemplate.template.table.columns.map((column, i) =>
              i === index ? e.target.innerHTML : column
            ),
          },
        },
      }));
    }
  };

  const handleSelectedInvoiceTemplateChange = async (newValue) => {
    // console.log('newValue', newValue);
    if (newValue === '') {
      setSelectedInvoiceTemplate('');
      setSelectedTemplete({});
      return;
    }
    invoiceTemplates.forEach((template) => {
      if (template.invoiceTempId === newValue) {
        setSelectedTemplete(template);
      }
      if (docId) {
        // console.log('logging docid', docId);
        fetchSingleDeliveryData(docId).then((data) => {
          // console.log('data', data);

          const validQuotes = data?.originalQuotes?.filter(
            (quote) => quote.status === true
          );
          // console.log('Valid Quotes:', validQuotes);
          setLineItems([]);
          setLineItems(validQuotes);

          setSelectedTemplete((prevTemplate) => ({
            ...prevTemplate, // Spread the previous template to maintain other properties
            template: {
              ...prevTemplate.template, // Spread the previous template's template object
              billDetail: {
                ...(prevTemplate.template?.billDetail || {}), // Safely spread billDetail or an empty object if undefined
                'Contact Name':
                  prevTemplate.template?.billDetail['Contact Name'] ||
                  data.primaryContactName ||
                  '',
                'Client Company Name':
                  prevTemplate.template?.billDetail['Client Company Name'] ||
                  data.agency ||
                  '',
                'Billing Street Address':
                  prevTemplate.template?.billDetail['Billing Street Address'] ||
                  data?.deliveryAddress?.split(',')[0] ||
                  '',
                'Billing City':
                  prevTemplate.template?.billDetail['Billing City'] ||
                  data?.deliveryAddress?.split(',')[1] ||
                  '',
                'Billing State':
                  prevTemplate.template?.billDetail['Billing State'] ||
                  data?.deliveryAddress?.split(',')[2] ||
                  '',
                'Billing Country':
                  prevTemplate.template?.billDetail['Billing Country'] ||
                  data?.deliveryAddress?.split(',')[3] ||
                  '',
                'Billing Zip Code':
                  prevTemplate.template?.billDetail['Billing Zip Code'] ||
                  (data?.deliveryAddress?.match(/\b\d{5,6}\b/) || [])[0] ||
                  '',
              },
              invoiceDetail: {
                ...(prevTemplate.template?.invoiceDetail || {}), // Safely spread invoiceDetail or an empty object if undefined
                'Invoice Number':
                  prevTemplate.template?.invoiceDetail['Invoice Number'] ||
                  data.spo ||
                  data.spoId,
                'Invoice Date':
                  prevTemplate.template?.invoiceDetail['Invoice Date'] ||
                  new Date(data.deliveryDate)
                    .toLocaleDateString('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    })
                    .replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'),
                'Due Date':
                  prevTemplate.template?.invoiceDetail['Due Date'] ||
                  new Date(
                    new Date(data.deliveryDate).getTime() +
                      30 * 24 * 60 * 60 * 1000
                  )
                    .toLocaleDateString('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    })
                    .replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'),
                Terms:
                  prevTemplate.template?.invoiceDetail['Terms'] ||
                  data.terms ||
                  'Add any Payment Terms you want to add here',
              },
              lineItems: {},
            },
          }));
        });
      }
    });
  };

  const handleSaveInvoice = async (content) => {
    try {
      setIsLoading(true);

      // Send a POST request to generate the invoice
      const response = await fetch(
        process.env.REACT_APP_URL_GENERATE_INVOICE,
        // 'http://localhost:3001/generateInvoice',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: content,
            docId: docId,
          }),
        }
      );

      if (response.ok) {
        const { url } = await response.json();
        const invoiceUrl = [url];

        // const formData = {
        //   invoiceUrl: invoiceUrl[0],
        // };

        // await updateDeliveryAttachments(
        //   formData,
        //   [
        //     {
        //       name: 'Invoice-' + docId,
        //       attachmentUrl: invoiceUrl[0],
        //     },
        //   ],
        //   docId
        // );

        setIsLoading(false);
        setOpenGenerateInvoicModal(false);
        setSelectedInvoiceTemplate('');
        setInvoiceUrl(invoiceUrl);
        window.open(invoiceUrl[0], '_blank');
      } else {
        throw new Error('Error generating invoice');
      }
    } catch (error) {
      console.error('Error generating or saving invoice:', error);
      setIsLoading(false);
    }
  };

  const getHTML = async () => {
    return compile(
      <Tailwind>
        <CSS>
          {`@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

      @page {
        size: a4;
        margin: .75in .75in 1in .75in;
      }
      `}
        </CSS>
        <div className="font-[inter] text-slate-800">
          <div className="bg-slate-100 -z-10 absolute -bottom-[1in] -right-[.75in] -left-[.75in] rounded-t-[.75in] h-[10vh]"></div>
          {selectedTemplete.template.backgroundImage['Background Image'] && (
            <img
              src={
                selectedTemplete.template.backgroundImage['Background Image'] ||
                ''
              }
              alt="Company Logo"
              className={`absolute inset-0 object-cover mx-auto my-auto opacity-${
                100 - selectedTemplete.template.backgroundImage['opacity']
              }`}
              style={{
                mixBlendMode: 'multiply',
                objectPosition: 'center',
              }}
            />
          )}
          <div className="flex items-start justify-between">
            <div>
              <p className="text-lg font-bold mb-2">
                {selectedTemplete.template['companyDetail']['Company Name'] ||
                  'Company Name'}
              </p>
              <p className="text-sm">
                {selectedTemplete.template['companyDetail']['Street Address'] ||
                  'Street Address'}{' '}
              </p>
              <p className="text-sm">
                {[
                  selectedTemplete.template['companyDetail']['City'],
                  selectedTemplete.template['companyDetail']['State'],
                  selectedTemplete.template['companyDetail']['Country'],
                ]
                  .filter(Boolean)
                  .join(', ')}
              </p>
              <p className="text-sm">
                {selectedTemplete.template['companyDetail']['Zip Code'] ||
                  'Zip Code'}{' '}
              </p>
              <p className="text-sm">
                {selectedTemplete.template['companyDetail']['Phone'] || 'Phone'}{' '}
              </p>
              <p className="text-sm">
                {selectedTemplete.template['companyDetail']['Email'] || 'Email'}{' '}
              </p>
            </div>
            <div className="flex-grow" />
            <div className="flex">
              <div className="flex text-xs text-white">
                {selectedTemplete.template.companyLogo['Company Logo'] && (
                  <img
                    src={
                      selectedTemplete.template.companyLogo['Company Logo'] ||
                      ''
                    }
                    alt="Company Logo"
                    className="h-24"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="p-6 rounded-xl mt-6 -mx-6">
            <h2 className="text-lg font-bold mb-2">Bill To</h2>
            <div className="flex">
              <div className="basis-0 flex-grow gap-4">
                <h2 className="font-bold text-sm">
                  {selectedTemplete.template['billDetail']['Contact Name'] ||
                    'Contact Name'}
                </h2>
                <p className="text-sm">
                  {selectedTemplete.template['billDetail'][
                    'Client Company Name'
                  ] || 'Client Company Name'}
                </p>
                <p className="text-sm">
                  {selectedTemplete.template['billDetail'][
                    'Billing Street Address'
                  ] || 'Billing Street Address'}
                </p>
                <p className="text-sm">
                  {[
                    selectedTemplete.template['billDetail']['Billing City'],
                    selectedTemplete.template['billDetail']['Billing State'],
                    selectedTemplete.template['billDetail']['Billing Country'],
                  ]
                    .filter(Boolean)
                    .join(', ')}
                </p>
                <p className="text-sm">
                  {selectedTemplete.template['billDetail'][
                    'Billing Zip Code'
                  ] || 'Billing Zip Code'}
                </p>
              </div>
              <div className="basis-0 flex-grow">
                <h2 className="font-bold text-lg mb-2">Invoice Details</h2>
                <p className="text-sm">
                  <strong>Invoice No:</strong>{' '}
                  {selectedTemplete.template['invoiceDetail'][
                    'Invoice Number'
                  ] || 'Invoice #'}
                </p>
                <p className="text-sm">
                  <strong>Invoice Date:</strong>{' '}
                  {selectedTemplete.template['invoiceDetail']['Invoice Date'] ||
                    'Invoice Date'}
                </p>
                <p className="text-sm">
                  <strong>Due Date:</strong>{' '}
                  {selectedTemplete.template['invoiceDetail']['Due Date'] ||
                    'Due Date'}
                </p>
                {selectedTemplete.template['invoiceDetail']['Terms'] && (
                  <p className="text-sm">
                    <strong>Terms:</strong>{' '}
                    {selectedTemplete.template['invoiceDetail']['Terms'] ||
                      'Terms'}
                  </p>
                )}
              </div>
            </div>
          </div>
          <table
            className="w-full divide-y divide-gray-200"
            style={{ maxWidth: '7.5in' }}
          >
            <thead className="bg-gray-100">
              <tr>
                {selectedTemplete.template.table.columns.map(
                  (column, index) => (
                    <th
                      key={index}
                      className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                      style={{
                        width: `${
                          100 / selectedTemplete.template.table.columns.length
                        }%`,
                      }}
                    >
                      {column.label}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {selectedLineItems.map((itemId, rowIndex) => {
                const item = lineItems.find(
                  (lineItem) => lineItem.uniqueId === itemId
                );
                return (
                  <tr key={rowIndex}>
                    {selectedTemplete.template.table.columns.map(
                      (column, colIndex) => (
                        <td
                          key={colIndex}
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 break-words"
                        >
                          {item
                            ? column.name === 'saiTax'
                              ? `${
                                  parseFloat(item[column.name] || 0) +
                                  parseFloat(item['tax'] || 0)
                                } %`
                              : column.name === 'saiSAndH'
                                ? `$ ${
                                    parseFloat(item[column.name] || 0) +
                                    parseFloat(item['sAndH'] || 0)
                                  }`
                                : column.name === 'saiTotal'
                                  ? `$ ${parseFloat(
                                      item[column.name] || 0
                                    ).toFixed(2)}`
                                  : column.name === 'gAndA' ||
                                      column.name === 'price'
                                    ? `$ ${parseFloat(item[column.name] || 0)}`
                                    : column.name === 'margin'
                                      ? `${parseFloat(item[column.name] || 0)}%`
                                      : item[column.name]
                            : 'N/A'}
                        </td>
                      )
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex mt-4">
            <div className="basis-0 flex-grow gap-4">
              <p className="text-lg font-bold mb-2">
                {selectedTemplete.template.message.content}
              </p>
            </div>
            <div className="basis-0 flex-grow">
              <table className="w-full my-2 border-collapse text-sm rounded-lg">
                <tbody className="bg-white">
                  <tr className="border-b text-slate-500">
                    <td className="text-left py-3 pl-4 bg-gray-100 font-bold">
                      Subtotal:
                    </td>
                    <td className="text-right py-3">
                      {`$ ${subtotal.toFixed(2)}` || '{{subtotal}}'}
                    </td>
                  </tr>
                  <tr className="border-b text-slate-500">
                    <td className="text-left py-3 pl-4 bg-gray-100 font-bold">
                      Tax:
                    </td>
                    <td className="text-right py-3">
                      {`$ ${tax.toFixed(2)}` || '{{tax}}'}
                    </td>
                  </tr>
                  <tr className="border-b text-slate-500">
                    <td className="text-left py-3 pl-4 bg-gray-100 font-bold">
                      Total Due:
                    </td>
                    <td className="text-right py-3">
                      {`$ ${total.toFixed(2)}` || '{{totalDue}}'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="p-6 bg-slate-100 rounded-xl -mx-6 mb-6 mt-6">
            <div className="flex">
              {selectedTemplete.template['payment']['Company Name'] && (
                <div className="basis-0 flex-grow gap-4">
                  <h2 className="mb-2 font-bold underline underline-offset-2">
                    Payment by Mail
                  </h2>
                  <h2 className="font-bold">
                    {selectedTemplete.template['payment']['Company Name']}
                  </h2>
                  <p className="text-sm">
                    {selectedTemplete.template['payment']['Street Address']}
                  </p>
                  <p className="text-sm">
                    {[
                      selectedTemplete.template['payment']['City'],
                      selectedTemplete.template['payment']['State'],
                      selectedTemplete.template['payment']['Country'],
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </p>
                  <p className="text-sm">
                    {selectedTemplete.template['payment']['Zip Code']}
                  </p>
                </div>
              )}

              {selectedTemplete.template['payment']['Company Name'] && (
                <div className="basis-0 flex-grow gap-4">
                  <h2 className="mb-2 font-bold underline underline-offset-2">
                    Payment by ACH
                  </h2>
                  <h2 className="text-sm">
                    <span className="font-bold">Bank Name: </span>
                    {selectedTemplete.template['payment']['Bank']}
                  </h2>
                  <p className="text-sm">
                    <span className="font-bold">Account Number: </span>
                    {selectedTemplete.template['payment']['Account Number']}
                  </p>
                  <p className="text-sm">
                    <span className="font-bold">Routing Number: </span>
                    {selectedTemplete.template['payment']['Routing']}{' '}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {selectedTemplete.template.footer.content && (
          <PageBottom className="text-xs">
            {selectedTemplete.template.footer.content}
          </PageBottom>
        )}
      </Tailwind>
    );
  };

  useEffect(() => {
    if (docId) {
      fetchSingleDeliveryData(docId).then((data) => {
        const validQuotes = data?.originalQuotes?.filter(
          (quote) => quote.status === true
        );
        setLineItems(validQuotes);
        const defaultSelected = validQuotes?.find(
          (item) => item?.vpo === selectedInvoiceRow?.vpoId
        );
        if (defaultSelected) {
          setSelectedLineItems([defaultSelected.uniqueId]); // Set the default item
        }
      });
    }
  }, [docId, selectedInvoiceRow]);

  return (
    <>
      {isLoading && <Loader />}
  
      <div
        id="addconfirmPOData-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          openGenerateInvoicModal
            ? 'fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative w-full max-w-6xl p-4 max-h-[95vh]">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* Modal Header */}
            <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Generate Invoice
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => {
                  setOpenGenerateInvoicModal(false);
                  setSelectedInvoiceTemplate('');
                  setInvoiceUrl('');
                }}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            
            {/* Modal Body */}
            <div className="p-4 space-y-4 overflow-y-auto max-h-[calc(95vh-10rem)]">
              <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                {/* Template Selection */}
                <div className="sm:col-span-2">
                  <label
                    htmlFor="selectInvoiceTemplate"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Select Invoice Template
                  </label>
                  <select
                    id="selectInvoiceTemplate"
                    className="bg-gray-100 cursor-pointer border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    required
                    value={selectedInvoiceTemplate}
                    onChange={(e) => {
                      handleSelectedInvoiceTemplateChange(e.target.value);
                    }}
                  >
                    <option value="">
                      {selectedTemplete.title || 'Selected Invoice Template'}
                    </option>
                    {invoiceTemplates.map((template) => {
                      if (template.title === selectedTemplete.title) {
                        return null;
                      }
                      return (
                        <option
                          key={template.invoiceTempId}
                          value={template.invoiceTempId}
                        >
                          {template.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
                
                {/* Template Details */}
                <div className="col-span-2">
                  {Object.keys(selectedTemplete).length > 0 && (
                    <div className="flex flex-col gap-4 w-full">
                      {/* Last Updated */}
                      <div>
                        <label className="text-sm font-medium text-gray-900 dark:text-white">
                          Last Updated
                        </label>
                        <input
                          disabled
                          value={new Date(selectedTemplete.updatedAt.seconds * 1000)
                            .toLocaleDateString('en-GB')
                            .split('/')
                            .reverse()
                            .join('-')}
                          type="text"
                          className="bg-gray-100 cursor-pointer border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        />
                      </div>
                      
                      {/* Line Items Selection */}
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="addLineItems"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Add Line Items
                        </label>
                        <select
                          id="addLineItems"
                          className="bg-gray-100 cursor-pointer border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          required
                          onChange={(e) => {
                            const value = e.target.value;
                            setSelectedLineItems((prevItems) => {
                              const exists = prevItems.includes(value);
                              if (exists) {
                                return prevItems.filter((item) => item !== value);
                              } else {
                                return [...prevItems, value];
                              }
                            });
                          }}
                        >
                          <option value="">Select Line Items</option>
                          {lineItems?.map((item) => (
                            <option key={item.uniqueId} value={item.uniqueId}>
                              {item.clin} : {item.itemName} from {item.vendorName}
                            </option>
                          ))}
                        </select>
                      </div>
                      
                      {/* Selected Line Items List */}
                      <div className="selected-items-list max-h-40 overflow-y-auto border rounded-lg p-2">
                        {selectedLineItems.length > 0 ? (
                          selectedLineItems.map((itemId, index) => {
                            const item = lineItems.find((item) => item.uniqueId === itemId);
                            return (
                              <div
                                key={index}
                                className="flex items-center justify-between p-2 border-b last:border-b-0"
                              >
                                <span className="text-sm truncate pr-2">
                                  {item
                                    ? `${item.clin} : ${item.itemName} from ${item.vendorName}`
                                    : 'Item not found'}
                                </span>
                                <button
                                  onClick={() => removeItem(itemId)}
                                  className="text-red-500 hover:text-red-700 flex-shrink-0"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </button>
                              </div>
                            );
                          })
                        ) : (
                          <p className="text-sm text-gray-500 p-2">No items selected</p>
                        )}
                      </div>
                      
                      {/* Invoice Preview */}
                      <div className="flex flex-col items-center justify-center mt-4">
                        <div className="w-full relative border border-gray-200 rounded-lg p-4 max-h-[60vh] overflow-y-auto">
                          {/* Watermark image overlay */}
                          {selectedTemplete.template.backgroundImage['Background Image'] && (
                            <img
                              src={selectedTemplete.template.backgroundImage['Background Image'] || ''}
                              alt="Company Logo"
                              className={`absolute inset-0 h-[80%] object-cover mx-auto my-auto opacity-${
                                100 - selectedTemplete.template.backgroundImage['opacity']
                              }`}
                              style={{
                                mixBlendMode: 'multiply',
                                objectPosition: 'center',
                              }}
                            />
                          )}
                          
                          {/* Company Details */}
                          <div className="grid grid-cols-3 gap-x-4 gap-y-4">
                            <div className="col-span-2 relative">
                              <p
                                className="text-lg font-bold mb-2"
                                contentEditable
                                suppressContentEditableWarning={true}
                                onBlur={(e) => handleOnBlur(e, 'companyDetail', 'Company Name')}
                                onFocus={(e) => handleOnFocus(e)}
                              >
                                {selectedTemplete.template['companyDetail']['Company Name'] || 'Company Name'}
                              </p>
                              <p
                                className="text-sm"
                                contentEditable
                                suppressContentEditableWarning={true}
                                onBlur={(e) => handleOnBlur(e, 'companyDetail', 'Street Address')}
                                onFocus={(e) => handleOnFocus(e)}
                              >
                                {selectedTemplete.template['companyDetail']['Street Address'] || 'Street Address'}
                              </p>
                              <p
                                className="text-sm"
                                contentEditable
                                suppressContentEditableWarning={true}
                                onBlur={(e) => handleOnBlurAddress(e, 'companyDetail', 'City', 'State', 'Country')}
                                onFocus={(e) => handleOnFocus(e)}
                              >
                                {[
                                  selectedTemplete.template['companyDetail']['City'],
                                  selectedTemplete.template['companyDetail']['State'],
                                  selectedTemplete.template['companyDetail']['Country'],
                                ]
                                  .filter(Boolean)
                                  .join(', ')}
                              </p>
                              <p
                                className="text-sm"
                                contentEditable
                                suppressContentEditableWarning={true}
                                onBlur={(e) => handleOnBlur(e, 'companyDetail', 'Zip Code')}
                                onFocus={(e) => handleOnFocus(e)}
                              >
                                {selectedTemplete.template['companyDetail']['Zip Code'] || 'Zip Code'}
                              </p>
                              <p
                                className="text-sm"
                                contentEditable
                                suppressContentEditableWarning={true}
                                onBlur={(e) => handleOnBlur(e, 'companyDetail', 'Phone')}
                                onFocus={(e) => handleOnFocus(e)}
                              >
                                {selectedTemplete.template['companyDetail']['Phone'] || 'Phone'}
                              </p>
                              <p
                                className="text-sm"
                                contentEditable
                                suppressContentEditableWarning={true}
                                onBlur={(e) => handleOnBlur(e, 'companyDetail', 'Email')}
                                onFocus={(e) => handleOnFocus(e)}
                              >
                                {selectedTemplete.template['companyDetail']['Email'] || 'Email'}
                              </p>
                            </div>
                            <div className="col-span-1 flex justify-center items-start">
                              {selectedTemplete.template.companyLogo['Company Logo'] && (
                                <img
                                  src={selectedTemplete.template.companyLogo['Company Logo'] || ''}
                                  alt="Company Logo"
                                  className="h-20 object-contain"
                                />
                              )}
                            </div>
                          </div>
                          
                          {/* Bill To and Invoice Details */}
                          <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-6">
                            <div className="col-span-1 relative">
                              <p
                                className="text-lg font-bold mb-2"
                                contentEditable
                                suppressContentEditableWarning={true}
                                onBlur={(e) => handleOnBlur(e, 'billDetail', 'Contact Name')}
                                onFocus={(e) => handleOnFocus(e)}
                              >
                                Bill To
                              </p>
                              <p
                                className="text-sm"
                                contentEditable
                                suppressContentEditableWarning={true}
                                onBlur={(e) => handleOnBlur(e, 'billDetail', 'Contact Name')}
                                onFocus={(e) => handleOnFocus(e)}
                              >
                                {selectedTemplete.template['billDetail']['Contact Name'] || 'Contact Name'}
                              </p>
                              <p
                                className="text-sm"
                                contentEditable
                                suppressContentEditableWarning={true}
                                onBlur={(e) => handleOnBlur(e, 'billDetail', 'Client Company Name')}
                                onFocus={(e) => handleOnFocus(e)}
                              >
                                {selectedTemplete.template['billDetail']['Client Company Name'] || 'Client Company Name'}
                              </p>
                              <p
                                className="text-sm"
                                contentEditable
                                suppressContentEditableWarning={true}
                                onBlur={(e) => handleOnBlur(e, 'billDetail', 'Billing Street Address')}
                                onFocus={(e) => handleOnFocus(e)}
                              >
                                {selectedTemplete.template['billDetail']['Billing Street Address'] || 'Billing Street Address'}
                              </p>
                              <p
                                className="text-sm"
                                contentEditable
                                suppressContentEditableWarning={true}
                                onBlur={(e) => handleOnBlurAddress(e, 'billDetail', 'Billing City', 'Billing State', 'Billing Country')}
                                onFocus={(e) => handleOnFocus(e)}
                              >
                                {[
                                  selectedTemplete.template['billDetail']['Billing City'],
                                  selectedTemplete.template['billDetail']['Billing State'],
                                  selectedTemplete.template['billDetail']['Billing Country'],
                                ]
                                  .filter(Boolean)
                                  .join(', ')}
                              </p>
                              <p
                                className="text-sm"
                                contentEditable
                                suppressContentEditableWarning={true}
                                onBlur={(e) => handleOnBlur(e, 'billDetail', 'Billing Zip Code')}
                                onFocus={(e) => handleOnFocus(e)}
                              >
                                {selectedTemplete.template['billDetail']['Billing Zip Code'] || 'Billing Zip Code'}
                              </p>
                            </div>
                            <div className="col-span-1">
                              <p
                                className="text-lg font-bold mb-2"
                                contentEditable
                                suppressContentEditableWarning={true}
                              >
                                Invoice Details
                              </p>
                              <div className="flex items-center mb-1">
                                <strong className="text-sm mr-2">Invoice No:</strong>
                                <p
                                  className="text-sm"
                                  contentEditable
                                  suppressContentEditableWarning={true}
                                  onBlur={(e) => handleOnBlur(e, 'invoiceDetail', 'Invoice Number')}
                                  onFocus={(e) => handleOnFocus(e)}
                                >
                                  {selectedTemplete.template['invoiceDetail']['Invoice Number'] || 'Invoice #'}
                                </p>
                              </div>
                              <div className="flex items-center mb-1">
                                <strong className="text-sm mr-2">Invoice Date:</strong>
                                <p
                                  className="text-sm"
                                  contentEditable
                                  suppressContentEditableWarning={true}
                                  onBlur={(e) => handleOnBlur(e, 'invoiceDetail', 'Invoice Date')}
                                  onFocus={(e) => handleOnFocus(e)}
                                >
                                  {selectedTemplete.template['invoiceDetail']['Invoice Date'] || 'Invoice Date'}
                                </p>
                              </div>
                              <div className="flex items-center mb-1">
                                <strong className="text-sm mr-2">Due Date:</strong>
                                <p
                                  className="text-sm"
                                  contentEditable
                                  suppressContentEditableWarning={true}
                                  onBlur={(e) => handleOnBlur(e, 'invoiceDetail', 'Due Date')}
                                  onFocus={(e) => handleOnFocus(e)}
                                >
                                  {selectedTemplete.template['invoiceDetail']['Due Date'] || 'Due Date'}
                                </p>
                              </div>
                              <div className="flex items-center mb-1">
                                <strong className="text-sm mr-2">Terms:</strong>
                                <p
                                  className="text-sm"
                                  contentEditable
                                  suppressContentEditableWarning={true}
                                  onBlur={(e) => handleOnBlur(e, 'invoiceDetail', 'Terms')}
                                  onFocus={(e) => handleOnFocus(e)}
                                >
                                  {selectedTemplete.template['invoiceDetail']['Terms'] || 'Terms'}
                                </p>
                              </div>
                            </div>
                          </div>
                          
                          {/* Table */}
                          <div className="mt-6 overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-100">
                                <tr>
                                  {selectedTemplete.template.table.columns.map((column, index) => (
                                    <th
                                      key={index}
                                      contentEditable
                                      suppressContentEditableWarning={true}
                                      className="px-3 py-2 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                                      onBlur={(e) => handleOnBlurTable(e, index)}
                                      onFocus={(e) => handleOnFocus(e)}
                                    >
                                      {column.label}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody className="bg-white divide-y divide-gray-200">
                                {selectedLineItems.map((itemId, rowIndex) => {
                                  const item = lineItems.find((lineItem) => lineItem.uniqueId === itemId);
                                  return (
                                    <tr key={rowIndex}>
                                      {selectedTemplete.template.table.columns.map((column, colIndex) => (
                                        <td
                                          key={colIndex}
                                          contentEditable
                                          suppressContentEditableWarning={true}
                                          className="px-3 py-2 whitespace-nowrap text-sm text-gray-500"
                                        >
                                          {item
                                            ? column.name === 'saiTax'
                                              ? `${
                                                  parseFloat(item[column.name] || 0) +
                                                  parseFloat(item['tax'] || 0)
                                                } %`
                                              : column.name === 'saiSAndH'
                                                ? `$ ${
                                                    parseFloat(item[column.name] || 0) +
                                                    parseFloat(item['sAndH'] || 0)
                                                  }`
                                                : column.name === 'saiTotal'
                                                  ? `$ ${parseFloat(item[column.name] || 0).toFixed(2)}`
                                                  : column.name === 'gAndA' || column.name === 'price'
                                                    ? `$ ${parseFloat(item[column.name] || 0)}`
                                                    : column.name === 'margin'
                                                      ? `${parseFloat(item[column.name] || 0)}%`
                                                      : item[column.name]
                                            : 'N/A'}
                                        </td>
                                      ))}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          
                          {/* Message and Totals */}
                          <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-4">
                            <div className="col-span-1 flex flex-col justify-center">
                              <p
                                className="text-lg font-bold mb-2"
                                contentEditable
                                suppressContentEditableWarning={true}
                                onBlur={(e) => handleOnBlur(e, 'message', 'content')}
                                onFocus={(e) => handleOnFocus(e)}
                              >
                                {selectedTemplete.template.message.content}
                              </p>
                            </div>
                            <div className="col-span-1">
                              {selectedTemplete.template.table.columns &&
                                selectedTemplete.template.table.columns.length > 0 &&
                                selectedTemplete.template.table.columns[0] !== '' && (
                                  <div className="border rounded-lg overflow-hidden">
                                    <table className="min-w-full divide-y divide-gray-200">
                                      <tbody className="bg-white divide-y divide-gray-200">
                                        <tr>
                                          <td
                                            contentEditable
                                            suppressContentEditableWarning={true}
                                            className="px-3 py-2 whitespace-nowrap text-sm text-gray-500 bg-gray-100"
                                          >
                                            Subtotal:
                                          </td>
                                          <td
                                            contentEditable
                                            suppressContentEditableWarning={true}
                                            className="px-3 py-2 whitespace-nowrap text-sm text-gray-500"
                                          >
                                            {`$ ${subtotal.toFixed(2)}` || '{{subtotal}}'}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            contentEditable
                                            suppressContentEditableWarning={true}
                                            className="px-3 py-2 whitespace-nowrap text-sm text-gray-500 bg-gray-100"
                                          >
                                            Tax:
                                          </td>
                                          <td
                                            contentEditable
                                            suppressContentEditableWarning={true}
                                            className="px-3 py-2 whitespace-nowrap text-sm text-gray-500"
                                          >
                                            {`$ ${tax.toFixed(2)}` || '{{tax}}'}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            contentEditable
                                            suppressContentEditableWarning={true}
                                            className="px-3 py-2 whitespace-nowrap text-sm text-gray-500 bg-gray-100 font-bold"
                                          >
                                            Total Due:
                                          </td>
                                          <td
                                            contentEditable
                                            suppressContentEditableWarning={true}
                                            className="px-3 py-2 whitespace-nowrap text-sm text-gray-500 font-bold"
                                          >
                                            {`$ ${total.toFixed(2)}` || '{{totalDue}}'}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                            </div>
                          </div>
                          
                          {/* Payment Information */}
                          <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-6">
                            {selectedTemplete.template['payment']['Company Name'] && (
                              <div className="col-span-1 relative">
                                <p
                                  className="text-lg font-bold mb-2 underline underline-offset-2"
                                  contentEditable
                                  suppressContentEditableWarning={true}
                                >
                                  Payment by Mail
                                </p>
                                <p
                                  className="text-sm"
                                  contentEditable
                                  suppressContentEditableWarning={true}
                                  onBlur={(e) => handleOnBlur(e, 'payment', 'Company Name')}
                                  onFocus={(e) => handleOnFocus(e)}
                                >
                                  {selectedTemplete.template['payment']['Company Name']}
                                </p>
                                <p
                                  className="text-sm"
                                  contentEditable
                                  suppressContentEditableWarning={true}
                                  onBlur={(e) => handleOnBlur(e, 'payment', 'Street Address')}
                                  onFocus={(e) => handleOnFocus(e)}
                                >
                                  {selectedTemplete.template['payment']['Street Address']}
                                </p>
                                <p
                                  className="text-sm"
                                  contentEditable
                                  suppressContentEditableWarning={true}
                                  onBlur={(e) => handleOnBlurAddress(e, 'payment', 'City', 'State', 'Country')}
                                  onFocus={(e) => handleOnFocus(e)}
                                >
                                  {[
                                    selectedTemplete.template['payment']['City'],
                                    selectedTemplete.template['payment']['State'],
                                    selectedTemplete.template['payment']['Country'],
                                  ]
                                    .filter(Boolean)
                                    .join(', ')}
                                </p>
                                <p
                                  className="text-sm"
                                  contentEditable
                                  suppressContentEditableWarning={true}
                                  onBlur={(e) => handleOnBlur(e, 'payment', 'Zip Code')}
                                  onFocus={(e) => handleOnFocus(e)}
                                >
                                  {selectedTemplete.template['payment']['Zip Code']}
                                </p>
                              </div>
                            )}
                            {selectedTemplete.template['payment']['Bank'] && (
                              <div className="col-span-1">
                                <p
                                  className="text-lg font-bold mb-2 underline underline-offset-2"
                                  contentEditable
                                  suppressContentEditableWarning={true}
                                >
                                  Payment by ACH
                                </p>
                                <p
                                  className="text-sm"
                                  contentEditable
                                  suppressContentEditableWarning={true}
                                  onBlur={(e) => handleOnBlur(e, 'payment', 'Bank')}
                                  onFocus={(e) => handleOnFocus(e)}
                                >
                                  Bank Name: {selectedTemplete.template['payment']['Bank']}
                                </p>
                                <p
                                  className="text-sm"
                                  contentEditable
                                  suppressContentEditableWarning={true}
                                  onBlur={(e) => handleOnBlur(e, 'payment', 'Account Number')}
                                  onFocus={(e) => handleOnFocus(e)}
                                >
                                  Account Number: {selectedTemplete.template['payment']['Account']}
                                </p>
                                <p
                                  className="text-sm"
                                  contentEditable
                                  suppressContentEditableWarning={true}
                                  onBlur={(e) => handleOnBlur(e, 'payment', 'Routing')}
                                  onFocus={(e) => handleOnFocus(e)}
                                >
                                  Routing Number: {selectedTemplete.template['payment']['Routing']}
                                </p>
                              </div>
                            )}
                          </div>
                          
                          {/* Footer */}
                          {selectedTemplete.template.footer.content && (
                            <div className="w-full mt-6 border-t border-gray-200 pt-4">
                              <p
                                className="text-sm text-gray-400 text-center"
                                contentEditable
                                suppressContentEditableWarning={true}
                                onBlur={(e) => handleOnBlur(e, 'footer', 'content')}
                                onFocus={(e) => handleOnFocus(e)}
                              >
                                {selectedTemplete.template.footer.content}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                
                {/* Invoice Preview Frame */}
                {invoiceUrl && (
                  <div className="col-span-2 mt-4">
                    <h4 className="text-sm font-medium text-gray-900 dark:text-white mb-2">Preview</h4>
                    <iframe
                      src={invoiceUrl}
                      width="100%"
                      className="h-64 border rounded-lg"
                      title="Invoice"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={async () => {
                  const html = await getHTML();
                  // console.log(html);

                  handleSaveInvoice(html);
                }}
              >
                Generate and Save
              </button>
              <button
                onClick={() => {
                  setOpenGenerateInvoicModal(false);
                  setSelectedInvoiceTemplate('');
                  setInvoiceUrl('');
                }}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 "
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GenerateInvoiceModal;
