import { app } from '../resources/gcp-config';
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  getDoc,
  updateDoc,
} from 'firebase/firestore';

const db = getFirestore(app);

// Fetch all email data with real-time updates
export const fetchAllEmailData = (callback) => {
  const emailCollectionRef = collection(db, 'email-records');
  return onSnapshot(emailCollectionRef, (querySnapshot) => {
    const emails = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      docId: doc.id,
    }));
    console.log('Fetched emails:', emails);
    callback(emails);
  });
};

// Fetch a single email record by its document ID
export const fetchSingleEmailData = (docId, onUpdate) => {
  const emailDocRef = doc(db, 'email-records', docId);
  const unsubscribe = onSnapshot(
    emailDocRef,
    (docSnap) => {
      const emailData = {
        ...docSnap.data(),
        docId: docSnap.id,
      };
      onUpdate(emailData);
    },
    (error) => console.error(`Error fetching document: ${error}`)
  );

  return unsubscribe;
};

// Update the 'classifyCategory' field of an email record
export const updateEmailClassify = async (docId, classifyCategory) => {
  try {
    const emailDocRef = doc(db, 'email-records', docId);

    // Update the 'classifyCategory' field in Firestore
    await updateDoc(emailDocRef, { classifyCategory });
    console.log(
      `Document ${docId} updated with classifyCategory: ${classifyCategory}`
    );

    // Fetch the updated email record
    const updatedDocSnap = await getDoc(emailDocRef);
    const emailRecord = { ...updatedDocSnap.data(), docId: updatedDocSnap.id };

    // Process the email via API, passing the complete email record
    const response = await fetch(
      process.env.REACT_APP_URL_MANUAL_MARK_RFQ,
      // 'http://localhost:3001/manual/mark-rfq',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailRecord),
      }
    );

    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    const data = await response.json();
    console.log('API Response:', data);

    return data;
  } catch (error) {
    console.error(`Error updating document or processing API: ${error}`);
  }
};
